.accordion {
  $root: &;

  &__head {

    .lede {
      @include mq(m) {
        padding: 0 25%;
      }
    }
  }


  &__item {
    border-top: 2px solid #D8D8D8;
    cursor: pointer;
    height: auto;
    max-height: 90px;
    overflow: hidden;
    padding: 15px 0;
    position: relative;
    transition: all 500ms;

    @include mq(m) {
      max-height: 65px;
    }

    .wrapper {
      position: relative;
    }

    &:hover {
      background: darken(#fff, 3);

      h3 {
        color: palette(muted, med);
      }
    }

    h3 {
      color: palette(muted, med);
      font-size: 23px;
      font-weight: 400;
      line-height: 1.2em;
      margin: 0;

      @include mq(m) {
        font-size: 23px;
      }
    }

    ul {
      margin-bottom: 1rem;
    }

    &__body {
      font-family: $font1;
      margin-top: 20px;
      opacity: 0;
      position: absolute;
      transition: all 500ms;

      @include mq(m) {
        position: relative;
      }
    }

    &__toggle {

      svg {
        float: right;
        height: 15px;
        position: absolute;
        right: 20px;
        top: 8px;
        transition: opacity 500ms;
        width: 15px;
      }

      &--on {
        svg {
          fill: palette(muted, med);
          opacity: 1;
        }
      }

      &--off {
        svg {
          fill: palette(branding, info);
          opacity: 0;
        }
      }
    }

    &.active {
      background: #e7f6f8;
      max-height: 500px;

      .accordion__item__toggle--off {
        svg {
          opacity: 1;
        }
      }

      .accordion__item__toggle--on {
        svg {
          opacity: 0;
        }
      }

      .accordion__item__body {
        opacity: 1;
        position: relative;
      }

      h3 {
        color: palette(branding, info);
      }

      #{$root}__item__body {
        opacity: 1;
      }
    }
  }
}
