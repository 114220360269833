.solution-block {

  &__one,
  &__two {
    // Main wrapper
    .wrapper {
      max-width: 1170px;
      padding: 0 16px;

      @include mq(xl) {
        max-width: 1640px;
        padding: 0 20px;
      }
    }
  }

  // Solution block one
  &__one {
    .solution-block__cards {
      @extend .grid__container__3-col;
    }
  }

  // View all link
  &__view-all {
    color: $dk-gray;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    justify-content: space-between;

    a {
      color: #535353;
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 19px;
      text-transform: uppercase;
    }
  }

  // Cards wrapper
  &__cards {
    @extend .grid__container;
    margin-top: 20px;
  }

  // Card
  &__card {
    margin-bottom: 45px;

    @include mq(m) {
      width: 49%;
    }

    @include mq(l) {
      width: 24%;
    }
  }

  // Image
  &__image {
    img {
      border: 1px solid rgba(46, 46, 46, 0.05);
      border-radius: 16px;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // Image with 'play video' overlay
  &__card.has-video-icon {
    .solution-block__image{
      a::before {
        content: url('/assets/svg/play-small.svg');
        position: absolute;
        line-height: 0;
      }
    }
  }

  // Content
  &__content {
    @extend .p2;
    line-height: 23px;
    margin-top: 25px;

    // Tags
    .tags {
      margin-bottom: 5px;

      li {
        @extend .tag;
        display: inline-block;
        font-style: italic;
        line-height: 21px;
        padding: 0;

        &:before {
          content:'';
        }

        &.hovertags:hover ul { display: inline; }

        &.hovertags {
          ul {
            display: none;
            background-color: palette(muted, xlight);
            border-radius: 5px;
            bottom: 25px;
            padding: 10px;
            position: absolute;
            width: 100px;

            @include mq(l) {
              width: 160px;
            }

            li {
              display: block;
              padding: 0;
            }
          }
        }
      }
    }

    // Title
    .title {
      color: $dk-gray;
      margin-bottom: 12px;

      &:before {
        height: 0;
      }
    }

    // Summary
    .summary {
      color: $dk-gray;
      margin-bottom: 12px;
      text-align: left;
    }

    // Type
    .solution-type {
      color: $lt-gray;
      display: block;
      &.aligned-right {
        float: right;
      }
    }
  }
}

// Solution cards - Horizontal
.solution-block__card-horizontal {
  .solution-block {
    &__card {
      @extend .solution-card__border;

      @include mq(l) {
        display: flex;
      }
    }
    &__image {
      @include mq(l) {
        max-width: 173px;
        margin-right: 20px;
        width: 100%;
      }
    }
    &__content {
      max-width: 100%;

      @include mq(l){
        margin-top: 0;
        width: 100%;
      }
    }
  }
}

