.featured-resource {

  // Featured image
  &__image img {
    @extend .rounded-img;
  }

  // Featured content
  &__content {
    @extend .solution-block__content;
    margin-bottom: 55px;

    .resource-type {
      color: $lt-gray;
      text-transform: none;
    }
  }
}
