.body--events {
  .cta.cta--heading-only {
    .title {
      font-size: 36px;
    }
    .cta__inner {
      padding: 5rem 10% 4rem;
    }
  }
}

.body--eventsIndex {
  .main > .section:first-of-type {
    @extend .page-header;
    color: $dk-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
}

.event-group {
  > .title {
    font-weight: 600;
    margin: 80px 0 50px;
  }
}

.event {
  display: flex;
  margin-top: 20px;

  @include mq(m) {
    margin-top: 50px;
  }

  &__date {
    margin: 0 0 10px;

    @include mq(m) {
      margin-right: 30px;
      margin-top: 100px;
    }
  }

  &__inner {
    width:100%;

    >.title {
      font-size: 48px;
    }

    p:first-of-type {
      margin-top: 50px;
    }
  }

  .panel {
    width: 100%;

    @include mq(m) {
      min-width: 30%;
      width: 252px;
      margin-left: 30px;
    }

    .title {
      font-weight: 500;
      margin-bottom: 30px;
      &:not(:first-of-type) {
        margin-top: 30px;
      }

      &:before {
        bottom: -10px;
      }
    }

    li {
      font-size: 16px!important;
    }
  }
}

.event {
  &__date {

    text-align: center;

    &__box {
      background-color: palette(muted, xlight);
      color: palette(branding, primary);
      font-size:70px;
      font-weight: 900;
      height: 125px;
      line-height: 50px;
      margin: 10px auto;
      padding: 40px 0;
      width: 150px;
    }
  }
}
.event-card {
  &__date {
    flex-grow: 1;
     -ms-flex-positive: 1;
      display: inline-block;
    font-family: $font1;
    text-align: center;
    width: 100%;

    @include mq(m) {
      flex: 1;
    }

    &__box {
      background-color: palette(muted, xlight);
      color: palette(branding, primary);
      font-size:70px;
      font-weight: 900;
      height: 125px;
      line-height: 50px;
      margin: 10px auto;
      padding: 40px 0;
      width: 150px;
    }
  }
}
.event,
.event-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq(m) {
    flex-wrap: nowrap;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 0;
    width: 100%;
  }

  .register-link,
  .directions-link,
  .contact-link {
    font-family: $font1;

    svg {
      fill: $dk-gray;
      height: 10px;
      width: 16px;
    }
  }
}

.event-card {
  background: #fff;
  border-radius: 5px;
  display: flex;
  margin: 1.5rem 0 3.4rem 0;
  position: relative;
  transition: box-shadow .5s;

  @include mq(l) {
    padding: 0 2rem;
  }

  &__image {
    @extend .solution-block__image;
    width: 100%;

    @include mq(l) {
      max-width: 366px;
      width: 40%;
    }
  }

  &__inner {
    margin: 25px 0 0 0;
    width: 100%;

    @include mq(m) {
      margin: 0 0 0 30px;
      padding: 10px;
      width: 60%;
    }

    span {
      color: $dk-gray;
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 19px;

      &.has-blue-color {
        color: $dk-blue;
      }
    }
  }

  &__details {
    color: $dk-gray;
    display: flex;
    flex-wrap: wrap;
    font-family: $font1;
    font-size: 14px;
    font-weight: normal;
    justify-content: space-between;
    margin: auto;

    .event-card__title {
      margin-bottom: 15px;
      margin-top: 6px;

      a {
        color: $dk-gray;
        font-size: 18px;
        font-weight: 800;
        line-height: 25px;
      }
    }

    li {
      list-style-type: none;
      padding-left: 0;
      width: 100%;

      &:before {
        content: '';
      }


    }

    @include mq(m) {
      text-align: inherit;
      li {
        width: auto;
        display: inline-block;
        margin-right: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  p {
    color: $dk-gray;
    font-size: 14px;
    font-weight: normal;
    line-height: 23px;
    width: 100%;
  }

  .register-link {
    @extend .button-outline;
    margin-right: 19px;

    svg {
      fill: $dk-gray;
      height: 10px;
      width: 16px;
    }
  }
}

// Featured event
.featured-event {

  h4 {
    @extend .featured-section-title;
    margin-bottom: 20px;
  }

  .event-card {
    flex-wrap: wrap;
    padding: 0;

    &__image {
      margin-bottom: 30px;
      max-width: 100%;
      width: 100%;
    }

    &__inner {
      margin-left: 0;
      width: 100%;
    }
  }
}
