.solutions-menu {
  border-bottom: 1px solid #CFCFCF;
  border-top: 1px solid #CFCFCF;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 20px;
  padding: 10px 0;

  @include mq(l) {
    max-width: 1140px;
  }

  @include mq(ml) {
    margin: 0 auto;
  }

  @include mq(xl) {
    max-width: 1600px;
    width: 97%;
  }

  &.mobile {
    border: 0 solid transparent;
    ul.solutions-menu__content {
      justify-content: left;
      li {
        width: 45%;
        margin-right: 5%;
        border-bottom: 1px solid #CFCFCF;
        white-space: normal;
        display: flex;
        align-items: center;
      }
      li:nth-child(-n + 2) {
        border-top: 1px solid #CFCFCF;
      }
    }
  }

  ul.solutions-menu__content,
  ul.solutions-menu__dropdown-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    width: 100%;

    li {
      @extend .tag;
      text-transform: uppercase;
      letter-spacing: .08em;
      line-height: 19px;
      padding: 10px 20px;
      white-space: nowrap;

      &:before {
        content: '';
      }

      a {
        color: $dk-gray;
      }
    }
  }

  ul.solutions-menu__dropdown-content {
    margin: 0 auto;
  }

  &__dropbtn {
    @extend .tag;
    border: none;
    line-height: 19px;
    text-transform: uppercase;
    font-family: $font1;

    &.active:after {
      transform: rotate(180deg);
    }

    &:after {
      background: url('/static/img/icons/icn-arrow.svg') no-repeat center;
      background-size: 10px;
      content: '';
      display: inline-block;
      height: 10px;
      margin-left: 10px;
      margin-top: 4px;
      width: 10px;
    }

    &:focus {
      outline: none;
    }
  }
}
