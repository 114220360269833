.box-links {
  background: #f5f5f5;
  padding: 3rem 0;


  @include mq(m) {
    padding: 3rem 5rem;
  }

  .title {
    @include mq(m) {
      padding: 1rem 7rem;
    }
  }

  .flickity-viewport {
    min-height: 350px;
    width: 100%;
  }

  &__col {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    height: 100%;
    width: 50%;

    @include mq(m) {
      width: 33.3333%;
    }

    @include mq(l) {
      width: 25%;
    }
  }

  &__listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3em 0;
    width: 100%;

    a {
      border:1px solid #f5f5f5;
      background-color: palette(base, body);
      color: palette(muted, med);
      display: flex;
      height: 50%;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-family: $font1;
      flex: 1 1 auto;
      padding: 1.5em 2em;
      transition: all .3s ease;

      &:hover {
        background-color: palette(branding, primary);
        color: #fff;
        text-decoration: none;

        span {
          color: #fff;
        }

        svg,
        path {
          fill: #fff;
        }
      }

      svg,
      path {
        fill: palette(muted, med);
        height: 4rem;
        transition: all .3s ease;
        width: 4rem;
      }
    }

    span {
      color: palette(muted, med);
      display: block;
      margin-top: 15px;
      line-height: 1.2em;
      transition: all .3s ease;
    }

    img {
      margin: auto;
    }

  }
}