.pl {
  margin-bottom: 30vh;

  &-header {
    border-bottom: 1px solid #E6E7E8;
    margin: 2em 0 .5em;
  }

  &-p {
    color: #707070;
    font-family: $font1;
  }

  &-color-blocks {
    display: flex;
    margin-bottom: 30px;

    &__item {
      align-items: center;
      color: #fff;
      display: flex;
      font-family: $font1;
      font-size: 21px;
      font-weight: 400;
      height: 150px;
      justify-content: center;
      margin-right: 1em;
      text-transform: uppercase;
      width: 150px;

      @each $label, $color in map-merge(
        map-get($palettes, branding),
        map-get($palettes, muted)
      ) {
        &--#{$label} {
          background-color: $color;
        }
      }

      &--invert {
        color: #555;
      }
    }
  }

  &-typography {
    margin: 30px 0;
    padding-right: 60%;
  }

  &-buttons {
    .button {
      margin-right: 1em;
    }

    p {
      margin-bottom: 15px;
    }
  }

}