.mobile-nav {
  align-items: center;
  background: $dk-blue;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity .2s ease;
  width: 100vw;
  z-index: 9;

  &__close {
    color: palette(muted,white);
    font-size: 25px;
    position: absolute;
    left: 20px;
    top: 28px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .nav-open & {
    opacity: 1;
    pointer-events: auto;
  }

  ul {
    margin: 0;
    text-align: center;
  }

  li {
    margin-bottom: 33px;
    text-align: left;

    &:before {
      content: '';
    }

    a {
      color: palette(muted,white);
      font-family: $font1;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__main {
    margin-top: 166px;

    ul {

      &.nav {
        width: 135px;
      }

      &.submenu {
        margin-top: 15px;

        li {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__secondary {
    background: #006BA1;
    bottom: 0;
    margin-top: 125px;
    padding: 20px 40px;
    position: absolute;
    width: 100%;

    ul {
      display: inline-block;

      &.nav {
        display: flex;
        justify-content: center;

        ul {
          li:last-of-type {
            margin-right: 0;
          }
        }
      }

      li {
        display: inline-block;
        margin-bottom: .5em;
        margin-right: 30px;

        a {
          color: palette(muted, white);
          font-size: 14px;
          font-weight: normal;
          line-height: 163.7%;
        }
      }
    }
  }
}
