.pagination {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;

  span,
  a.button {
    border: none;
    border-radius: 50%;
    box-shadow: none;
    color: $dk-gray !important; // Needed to override !important tag on buttons.scss line 27
    font-size: 14px;
    line-height: 23px;
    margin: 0 .2em;
    padding: 5px;
    width: 32px;
    height: 32px;
  }

  .number,
  .ellipses {
    &.active {
      a.button {
        background: $dk-green;
        border-color: $dk-green;
        box-shadow: none;
        color: #fff !important;
      }
    }
  }

  .right-arrow {
    a.button {
      border: 1px solid $dk-gray;

      &:after {
        content: '';
        display: block;
        background: url('/static/img/icons/icn-arrow-right.svg') center center no-repeat;
        height: 19px;
        width: 20px;
      }
    }
    &:hover {
      a.button {
        background: $dk-green;
        border-color: $dk-green;
      }
    }
  }

  .next {
    svg {
      margin-left: 8px;
    }
  }

  .prev {
    svg {
      margin-right: 8px;
    }
  }

  .current {
    background: palette(branding, info);
    color: #fff!important;
    a {
      color: #FFF;
    }
  }

  svg {
    fill: palette(muted, dark);
    height: 10px;
    width: 23px;
  }

  .disabled {
    opacity: .5;

    a {
      cursor: not-allowed;
    }

    svg {
      fill: palette(muted, mlight);
    }
  }
}
