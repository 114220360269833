// Only apply these base styles to rich text fields etc

/* ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
} */

/* .styled-lists,
.wysiwyg { */

  ul,
  ol {
    @include smoothing(false);
    list-style-type: none;
    margin: 0 0 30px;
    padding: 0;

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: none;

    li {
      margin-bottom: 0;
      padding-left: 16px;
      position: relative;
      .filter-bar &, .sort-box &, .resource-role-count__body &, .panel & {
        padding-left: 0;
      }

      &::before {
        color: palette(branding, primary);
        content: '•';
        left: 0;
        position: absolute;
        .filter-bar &, .sort-box &, .resource-role-count__body &, .panel & {
          content: none;
        }
      }
    }
    &.nav, &.pagination {
      li {
        padding-left: 0;
        &::before {
          content: none;
        }
      }
    }
  }

  ol {
    counter-reset: item;
    list-style-type: none;

    li {
      padding-left: 23px;
      position: relative;

      &::before {
        color: palette(branding, primary);
        content: counter(item) '.';
        counter-increment: item;
        left: 0;
        position: absolute;
      }
    }
  }

  dl {
    margin-bottom: 2em;

    dt {
      font-weight: bold;
      margin-top: .5em;
    }

    dd {
      margin: 0;
    }
  }

/* } */