.lighting-calculator {
  padding-bottom: 40px;

  &__inner {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  &__inner--center {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__calc,
  &__body {
    width: 100%;

    @include mq(l) {
      width: 48%;
    }
  }

  &__calc {
    order: 1;

    @include mq(l) {
      order: 0;
    }
  }

  .calc-help-text {
    em {
      color: #6c6e6c;
    }
  }
}