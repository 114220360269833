.utility-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0,0,0,.2);
  margin: 1.5rem 0;
  padding: 1.5rem 2rem 2rem;
  position: relative;
  transition: box-shadow .5s;

  h3 {
    @include mq(m) {
      padding-right: 150px;
    }
    span {
      color: palette(muted, dark);
      font-size: 16px;
      font-weight: 300;
    }
  }

  &:hover {
    box-shadow: 0 3px 25px rgba(0, 0, 0, .25);
  }

  address {
    color: palette(muted, dark);
  }

  .phone {
    color: palette(muted, dark);
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
  }

  p:last-child {
    margin: .5rem 0 1rem;

    @include mq(m) {
      margin: 00 0;
    }
  }
  p {
    margin: 0;
    a {
      color: palette(branding, info);
      font-family: $font1;
      font-style: normal;
      font-weight: 700;
    }
  }

  .button {
    @include mq(m) {
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
  }
}

.utility-search {
  .no-results {
    color: palette(muted, dark);
    margin: 50px;
    text-align: center;
  }
}
