.collection {
  margin-top: 50px;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .remaining {
    margin: 0 15px 0 0;
    transition: opacity 500ms;

    &.isLoading {
      opacity: .5;
    }

    @include mq(m) {
      flex: 1;
    }
  }

  .added {
    margin: 0 15px;
    transition: all 500ms;

    @include mq(m) {
      flex: 1;
    }
  }

  .continue-btn {
    cursor: pointer;
    @at-root #{&}--fixed {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 900;
    }

    svg {
      height: 25px;
      left: -5px;
      position: relative;
      top: 6px;
      width: 25px;
    }

    &:hover {
      color: palette(branding, info);

      svg {
        fill: palette(branding, info);
      }
    }
  }

  .publish-btn {
    margin-bottom: 50px;
  }

  .form {
    flex: 1;
    margin: 0 0 0 15px;
    padding: 0;
    transition: opacity 500ms;

    .title {
      margin-top: 0;
    }

    input,
    textarea {
      margin-bottom: 20px;
    }
  }
}

.resource-modal__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.4);

  .resource-modal {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    margin: 15vh 2vw;

    @include mq(l) {
      margin: 15vw;
    }

    .resource-type {
      margin-top: -50px;
      margin-bottom: 20px;
    }

    .column.content {
      flex: 3;
      padding-right: 2rem;
    }

    .column.details {
      width: 100%;

      @include mq(l) {
        flex: 1;
        border-left: 1px solid #DDD;
        padding-left: 2rem;
      }

      a {
        display: block;
        cursor: pointer;
        border-bottom: 1px solid #DDD;
        padding: 5px 0;
        small {
          color: #bbb;
          display: block;
          white-space: nowrap;
        }
      }
      .in-collection {
        color: palette(branding, primary);
        svg {
          width: 13px;
          height: 13px;
          fill: palette(branding, primary);
        }
      }
      .view-details,
      .download {
        svg {
          width: 13px;
          height: 13px;
          fill: palette(branding, info);
        }
      }
      .rating {
        display: block;
        margin-top: 10px;
      }
    }
  }
}

.collection-entry {
  .collection-title {
    margin: 50px 0 10px;
    text-transform: uppercase;
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include mq(m) {
      flex-direction: row;
    }

    .details {
      color: palette(muted, med);
      flex: 2;
      margin-top: 1.5rem;
    }

    .resources {
      flex: 10;

      @include mq(m) {
        margin-left: 50px;
      }
    }
  }

  .util-bar {

    &.button {
      background-color: palette(branding, info);
      color: #FFF;
      display: block;

      span {
        display: block;
        width: 100%;
      }

      svg {
        fill: #FFF;
      }
    }
  }
}

.sticky.stick {
  .sticky-element {
    position: fixed;
    top: 12px;
    z-index: 10000;
  }
}

[v-cloak] {
  display: none;
}