.landing-page-content {
  margin-top: 0;
  margin-bottom: -1px; // covers the gap that occasionally appears between the footer and the background images/gradients
  font-family: $font1;
  .body {
    font-size: 20pt;
    line-height: 30pt;
    font-weight: 500;
    @include mq($until: m) {
      font-size: 16pt;
      line-height: 24pt;
    }
  }
  .landing_page__segment {
    position: relative;
    padding: 2rem 0;
    margin-top: -50px;
    &.background_type_image {
      background-color: white;
      @include mq($until: l) {
        .background-cover {
          opacity: .1;
        }
      }
    }
    .background-cover {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @include mq(l) {
      margin-top: -100px;
    }
    .segment_content {
      padding-top: 2rem;
      padding-bottom: 2rem;
      @include mq(l) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    }
    &:before {
      content: "";
      height: 50px;
      display: block;
      @include mq(l) {
        height: 100px;
      }
    }
    &:after {
      content: "";
      height: 50px;
      display: block;
      @include mq(l) {
        height: 100px;
      }
    }
    &:first-child {
      margin-top: -50px;
      @include mq(l) {
        margin-top: -200px;
      }
      &:before {
        height: 50px;
        @include mq(l) {
          height: 200px;
        }
      }
    }
    &:last-child {
      .next-section-arrows {
        display: none;
      }
    }

    &:nth-child(odd) {
      // top border slope up
      -webkit-clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
      @include mq(l) {
        -webkit-clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);
      }
    }
    &:nth-child(even) {
      // top border slope down
      -webkit-clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
      clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
      @include mq(l) {
        -webkit-clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
      }
    }
    &:first-child {
      // top border severe slope up
      -webkit-clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
      @include mq(l) {
        -webkit-clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);
      }
    }

    &.segment_type_textBlock {
      .segment_content {
        clear: both;
        @include mq(l) {
          .align-right {
            padding-left: 50%;
          }
          .align-left {
            padding-right: 50%;
          }
        }
        .headline {
          color: $blue;
          * {
            font-size: inherit;
            color: inherit;
            margin: 0;
          }
        }
        .next-section-arrows {
          text-align: center;
        }
      }
    }
    &.segment_type_textIcons {
      color: white;
      .headline {
        * {
          font-size: inherit;
          color: inherit;
          margin: 0;
        }
      }
      .body {
        margin-bottom: 4em;
        * {
          color: inherit;
        }
      }
      .icons {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem;
        @include mq($until: m) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include mq(larger) {
          //grid-template-columns: repeat(4, 1fr);
        }
        > * {

          //margin: .5em;
        }
        .icon {
          position: relative;
          padding-top: 100%;
          overflow: hidden;
          border: 2px solid white;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .icon-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            color: inherit;
            .icon_image {
              height: 50%;
              width: 100%;
              svg {
                width: 100%;
                height: 100%;
                max-height: 100%;
                max-width: 100%;
              }
            }
            .icon_text {
              text-transform: uppercase;
              letter-spacing: .16em;
              font-size: 14pt;
              font-weight: 700;
              text-align: center;
              line-height: 14pt;
              @include mq($until: l) {
                font-size: 10pt;
                line-height: 10pt;
              }
              @include mq($until: m) {
                font-size: 8pt;
                line-height: 8pt;
              }
              * {
                color: inherit;
                letter-spacing: inherit;
                font-size: inherit;
                font-weight: inherit;
                margin: 0;
                line-height: inherit;
              }
            }
          }
          &:hover {
            color: $blue;
            border-color: white;
            background-color: white;
            svg, svg * {
              stroke: $blue;
              fill: $blue;
            }
          }
        }
      }
      .next-section-arrows {
        text-align: center;
      }
    }
    &.segment_type_textCards {
      color: white;
      .headline {
        text-align: center;
        font-weight: 900;
        font-size: 44pt;
        line-height: 44pt;
        margin-bottom: 1em;
        @include mq($until: m) {
          font-size: 36pt;
          line-height: 36pt;
        }
        * {
          font-size: inherit;
          color: inherit;
          margin: 0;
        }
      }
      .body {
        text-align: center;
        margin-bottom: 4em;
        * {
          color: inherit;
          line-height: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }
      .cards {
        justify-content: center;
        display: grid;
        grid-template-columns: auto;
        grid-gap: 1rem;
        @include mq(m) {
          grid-template-columns: auto auto auto;
        }
        @include mq(l) {
          grid-template-columns: auto auto auto;
        }
        .card {
          height: 100%;
          border-radius: 5px;
          overflow: hidden;
          .opacity-overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 3rem 2rem 2rem 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

          }
          * {
            color: white;
          }
          &:hover {
            box-shadow: 0 3px 25px rgba(0,0,0,.45);
          }
          .card_headline {
            text-transform: uppercase;
            font-weight: 900;
            font-size: 16px;
            line-height: normal;
            letter-spacing: .160em;
            margin-bottom: 2em;
          }
          .card_body {
            font-size: 14pt;
            font-weight: 500;
            line-height: 1.42em;
            margin-bottom: 2em;
            p {
              margin-bottom: 0;
            }
          }
          .card_button {
            background-color: white;
            text-transform: uppercase;
            width: fit-content;
            color: darkslategray;
            padding: .5em 1.5em;
            border-radius: 5px;
            font-size: 14pt;
            letter-spacing: .160em;
            font-weight: 900;
          }
        }
      }
      .next-section-arrows {
        text-align: center;
      }
    }
  }
}

.landing_page__hero {
  .gradient-overlay {
    padding-top: 5rem;
    background-image: linear-gradient(to bottom right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  }
  &:after {
    content: "";
    height: 50px;
    display: block;
    @include mq(l) {
      height: 200px;
    }
  }
  .logo {
    width: 350px;
    max-width: 100%;
    margin-bottom: 5rem;
  }
  h1, h1 * {
    color: white;
    font-weight: 900;
    font-size: 42px;
    margin-bottom: 0;
    line-height: 133%;
    @include mq($until: l) {
      font-size: 42px;
    }
    @include mq($until: m) {
      font-size: 42px;
    }
  }
  .next-section-arrows {
    width: 50%;
    text-align: center;
    margin-top: 4em;
  }
}

.sticky-newsletter-bar {
  background-color: palette(muted, dark);
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  @include mq(m) {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  @include mq(l) {
    display: flex;
  }
  .close-button {
    order: 2;
    width: 25px;
    height: 25px;
    float: right;
    margin-left: 2em;
    &:hover {
      cursor: pointer;
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .content {
    order: 1;
    flex-grow: 2;
    justify-content: space-between;
    align-items: center;
    @include mq(l) {
      display: flex;
    }
    .message {
      margin-bottom: 1em;
      @include mq(l) {
        margin: 0;
      }
      h4 {
        font-size: 14px;
        line-height: normal;
        @include mq($until: m) {
          font-size: 16pt;
          line-height: 16pt;
        }
      }
      h6 {
        font-size: 13pt;
        line-height: 20pt;
        font-weight: 500;
        margin-top: -6px;
        @include mq($until: m) {
          font-size: 12pt;
          line-height: 12pt;
          margin: 1em 0;
        }
      }
    }
    .signup-button-section {
      display: flex;
      justify-content: center;
      .signup-button {
        background-color: $blue;
        border-radius: 5px;
        padding: .5em 2em;
        text-align: center;
        display: flex;
        justify-content: center;
        a {
          color: white;
          text-transform: uppercase;
          font-size: 12pt;
          //line-height: pt;
          font-weight: 900;
          letter-spacing: .1em;
        }
      }
    }
  }
}
.next-section-arrows {
  margin-top: 4rem;
  @include mq(l) {
    margin-top: 8rem;
  }
  img {
    width: 75px;
  }
}