html {
    background: #131313;
    &.smoothScroll {
        scroll-behavior: smooth;
    }
}
body {
    background: #ffffff;
}

// Two columns
.columns {

    // Left column
    .content__left {
        .wrapper {
            padding: 0;
        }

        @include mq(l) {
            padding-right: 112px;
            width: 68.5%;
        }
    }

    // Right column
    .content__right {
        .wrapper {
            padding: 0;
        }

        @include mq(l) {
            width: 31.5%;
        }
    }
}

// Grid container
.grid__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-column-gap: 1.32%;
    -webkit-column-gap: 1.32%;
    -moz-column-gap: 1.32%;

    .grid__card,
    > div {
        align-self: flex-start;
        margin-bottom: 45px;
        width: 100%;

        @include mq(m) {
            width: 49%;
        }

        @include mq(l) {
            width: 24%;
        }
    }

    .uk-card-body {
        padding: 0;
    }
}

// Grid container - 3 columns
.grid__container__3-col {
    @extend .grid__container;
    justify-content: space-between;

    @include mq(l) {
        flex-wrap: nowrap;
    }

    .grid__card,
    > div {

        &:nth-child(3) {

            @include mq(m) {
                width: 100%;
            }

            @include mq(l) {
                margin-left: 70px;
                max-width: 480px;
                width: 43%;
            }

            @include mq(xl) {
                margin-left: 115px;
                max-width: 667px;
            }

            .wrapper {
                padding: 0;
            }
        }
    }
}
