img,
iframe,
picture,
figure {
  margin: 0;
  width: 100%;
}

img {
  &[src$='.svg'] {
    display: block;
    height: auto;
    width: 100%;
  }
}

.rounded-img {
  border: 1px solid rgba(46,46,46,.05);
  border-radius: 16px;
}
