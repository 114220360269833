$mq-show-breakpoints: (s, m, l, xl);

$mq-breakpoints: (
	s:		320px,
	m:		740px,
	l:		980px,
	ml: 	1175px,
	xl:		1300px,
	xxl: 	1440px,

	// tweakpoints
	larger: 1175px
);

// MQ debug styles
body:before {
	background:#000!important;
	border:none!important;
	bottom:0!important;
	color:#fff!important;
	display:none!important;
	opacity:.5;
	top:auto!important;
}

html[data-craftenv="local"] body:before {
	display:block!important;
}