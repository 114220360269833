.simple-text {

  &__inner {
    padding: 0;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}