.resource-role-count {
  background-size: cover;
  font-family: $font1;
  padding: 4rem 0;

  @include mq(m) {
    padding: 4rem 10%;
  }

  &__inner {
    @include mq(m) {
      margin: 4rem 5rem 0;
    }
  }

  &__header {
    @include smoothing(true);
    background-color: rgba(0, 93, 124, .7);
    border-radius: 10px 10px 0 0;
    color: #FFF;
    font-weight: 700;
    margin: 0 .5rem;
    padding: .5rem 1rem 0;

    span {
      &:last-child {
        float: right;
      }
    }
  }

  &__body {
    background-color: #FFF;
    border: 1px solid palette(muted, light);
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .5);
    color: palette(muted, med);
    font-size: 16px;
    padding: 1.25rem 1.5rem .5rem;

    @include mq(m) {
      font-size: 22px;
    }

    ul {
      margin: 0;
    }

    li {
      padding-bottom: 10px;

      span {
        @include smoothing(true);
        background-color: palette(branding, info);
        border-radius: 3px;
        color: #FFF;
        float: right;
        font-size: 12px;
        padding: 0 6px;
      }

      &:not(:first-child) {
        padding-top: 10px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #CCC;
      }
    }

    a {
      color: palette(muted, med);
      transition: color .3s ease;

      &:hover,
      &:focus {
        color: palette(muted, xdark);
      }
    }
  }

}
