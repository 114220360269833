figure {
  position: relative;

  @include mq(m) {
    width: 55%;
  }

  &.right {
    @include mq(m) {
      float: right;
      padding-left: 35px;
    }

    figcaption {
      @include mq(m) {
        margin-left: 3rem;
        margin-right: -2rem;
      }
    }
  }

  &.left {
    @include mq(m) {
      float: left;
      padding-right: 35px;
    }

    figcaption {
      @include mq(m) {
        margin-left: -2rem;
        margin-right: 7rem;
      }
    }
  }

  &.odd {
    figcaption {
      background: rgba(palette(branding, danger), .9);
    }
  }

  &.even {
    figcaption {
      background: rgba(palette(branding, primary), .9);
    }
  }

  img {
    margin-bottom: 35px;
    display: block;
    width: 100%;
  }

  figcaption {
    @include smoothing(false);
    color: #fff;
    font-family: $font1;
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 2rem;
    padding: 1.5rem 1.6em;
    position: relative;
    z-index: 2;

    @include mq(m) {
      margin-top: -3rem;
    }
  }
}