.filter {
  font-family: $font1;
  margin-bottom: 60px;

  &__clear-all {
    cursor: pointer;
    font-size: 14px;
  }

  &__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
    flex-wrap: wrap;

    @include mq(m) {
      align-items: baseline;
      flex-direction: row;
    }

    .search-form {
      margin-top: 10px;
      position: relative;
      margin-top: 15px;
      flex-grow: 1;
      @include mq(m) {
        margin-top: auto;
        margin-left: 17px;
      }

      input {
        border: 0 solid transparent;
        color: $lt-gray;
        border-bottom: 2px solid rgba(46, 46, 46, 0.05);

        &::placeholder {
          color: $lt-gray;
          font-style: normal;
        }

      }

      .search-icon {
        position: absolute;
        top: 7px;
        right: 0;
        background: transparent;
        outline: none;
        padding: 0;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .sort-dropdown,
    .share-dropdown {
      position: relative;

      .button--sort {
        width: 100%;
      }

      .sort-box,
      .share-box {
        background-color: #FFF;
        border: 1px solid darken(palette(muted, xlight), 5);
        border-radius: 8px;
        color: palette(muted, light);
        margin-top: 8px;
        overflow: hidden;
        position: absolute;
        min-width: 200px;
        z-index: 9;

        &.share-box {
          min-width: 250px;
          right: 0;
        }

        ul {
          margin-bottom: 0;

          li, a {
            color: palette(muted, light);
            cursor: pointer;

            svg {
              fill: palette(muted, light);
              height: 13px;
              width: 14px;
              margin-right: 5px;
            }

            &:hover {
              color: #FFF;
              background-color: palette(branding, info);

              svg {
                fill: #FFF;
              }
            }

            & a {
              display: block;
              padding: 10px 15px;
              width: 100%;
            }
          }
        }
      }

      &.sort-dropdown {
        width: 100%;
        @include mq(m) {
          width: auto;
        }

        .button {
          &.active {
            background-color: palette(muted, xlight);
          }
        }
      }

      &.share-dropdown {
        .button {
          &.active {
            background-color: darken(palette(branding, primary), 10);
            border-color: darken(palette(branding, primary), 10);
          }
        }
      }
    }

    .filter-button {
      background-color: palette(branding, info);
      border-color: palette(branding, info);
      box-shadow: none;
      color: #FFF;
      display: inline-block;

      svg {
        fill: #FFF;
        height: 14px;
        margin-right: 0;
        position: relative;
        top: 2px;
        width: 14px;
      }

      &:active,
      &:hover,
      &:focus {
        background-color: darken(palette(branding, info), 5);
        border-color: darken(palette(branding, info), 5);
      }
    }

    .share-button {
      background-color: palette(branding, primary);
      border-color: palette(branding, primary);
      box-shadow: none;
      color: #FFF;
      display: inline-block;
      margin-right: 10px;

      svg {
        fill: #FFF;
        height: 12px;
        margin-right: 0;
        position: relative;
        top: 1px;
        width: 12px;
      }

      &:active,
      &:hover,
      &:focus {
        background-color: darken(palette(branding, primary), 5);
        border-color: darken(palette(branding, primary), 5);
      }
    }

    label {
      color: palette(muted, med);
      font-weight: 500;
      margin-right: 8px;
    }
  }

  &__mid {
    border: 1px solid #ccc;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 1rem;
    overflow-x: scroll;
    padding: 1.2rem 2rem 2.5rem;
    position: relative;

    h3 {
      border-bottom: 1px solid #ccc;
      font-size: 21px;
      font-weight: 500;
      margin: 0 0 .5em;
    }

  }

  .filter-background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background-color: rgba(10, 10, 10, .7);
    z-index: 99;
    will-change: opacity;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s
  }

  .filter-bar {
    background-color: #FFF;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    overflow-y: scroll;
    will-change: transform;
    transition: max-width 600ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 100;
    width: 100%;

    @include mq(m) {
      max-width: 340px;
    }

    &.group-selected {
      max-width: 680px;
    }

    &__header {
      font-family: $base-font-family;
      color: #FFF;
      font-weight: 700;
      background-color: palette(branding, info);
      padding: 20px;
      @include smoothing;

      svg {
        cursor: pointer;
        fill: #FFF;
        width: 14px;
        height: 14px;
        float: right;
        top: 5px;
        position: relative;
      }
    }

    .row {
      @include mq(m) {
        display: flex;
      }

      .column {
        padding: 25px 20px;
        width: 100vw;
        @include mq(m) {
          flex: 1;
        }

        .bar-back {
          cursor: pointer;
          display: block;
          margin-bottom: 10px;
        }
      }
    }

    ul:not(.event-types) {
      background-color: #FFF;
      border: 1px solid darken(palette(muted, xlight), 5);
      border-radius: 8px;
      color: palette(muted, light);
      min-width: 300px;
      overflow: hidden;

      li,
      a {
        color: palette(muted, light);
        cursor: pointer;
        padding: 8px 12px;

        &:not(:last-child) {
          border-bottom: 1px solid darken(palette(muted, xlight), 5);
        }

        svg {
          width: 16px;
          height: 14px;
          fill: darken(palette(muted, light), 5);
        }

        &:hover {
          color: #FFF;
          background-color: darken(palette(branding, info), 5);

          svg {
            fill: #FFF;
          }
        }

        &.checked {
          color: #FFF;
          background-color: palette(branding, primary);

          svg {
            fill: #FFF;
          }

          &:hover {
            background-color: darken(palette(branding, primary), 5);
          }
        }

        &.selected {
          color: #FFF;
          background-color: darken(palette(branding, info), 5);

          svg {
            fill: #FFF;
          }
        }

        .checkbox-wrapper {
          float: right;
        }

        .chevron-wrapper {
          float: right;

          svg {
            width: 12px;
            height: 10px;
          }
        }
      }
    }

    .button-done {
      background-color: palette(branding, primary);
      border-color: palette(branding, primary);
      box-shadow: none;
      color: #FFF;
      margin-top: 20px;
      width: 100%;

      &:hover,
      &:active,
      &:focus {
        background-color: darken(palette(branding, primary), 5);
        border-color: darken(palette(branding, primary), 5);
      }
    }

    label {
      color: palette(muted, med);
    }
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(100%);
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 600ms cubic-bezier(0.77, 0, 0.175, 1);
    position: fixed;
  }

  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-100%);
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: transform 600ms cubic-bezier(0.77, 0, 0.175, 1);
    position: absolute;
  }

  &__category {
    flex: 0 0 80%;

    @include mq(m) {
      flex: 0 0 20%;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }

    h3 {
      white-space: nowrap;
    }

    label {
      color: palette(muted, dark);
    }

    ul {
      line-height: 20px;
      margin: 15px 0;
    }
  }

  &__active {
    width: 100%;

    @include mq(m) {
      width: auto;
    }

    ul {
      display: inline-flex;
      flex-wrap: wrap;
      float: left;
      width: 100%;
      @include mq(m) {
        width: auto;
      }
    }

    li {
      border: 1px solid $lt-gray;
      border-radius: 100px;
      color: $lt-gray;
      display: inline-block;
      margin-bottom: .6rem;
      margin-right: .8rem;
      padding: 5px 12px 5px 12px;
      position: relative;
      width: 100%;

      &:hover {
        background-color: rgba(127, 190, 211, 0.2);
        border: 1px solid $dk-blue;

        a {
          color: $dk-blue;
        }
      }

      &.active {
        border: 1px solid $dk-blue;

        a {
          color: $dk-blue;
        }

        svg {
          display: inline;
        }
      }

      a {
        text-decoration: none;
        color: $lt-gray;
        font-size: 14px;
        display: flex;
        align-items: center;
      }

      @include mq(m) {
        width: auto;
      }
    }

  }

  &__bottom {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding-top: 1.1rem;
    position: relative;

    button {
      background-color: palette(branding, info);
      bottom: 5rem;
      color: #fff;
      position: absolute;
      right: 1rem;

      &:hover,
      &:focus {
        background-color: palette(branding, info-dark);
        border: 1px solid palette(branding, info-dark);
        box-shadow: none;
      }
    }

    svg {
      height: 18px;
      width: 18px;
      margin-left: 10px;
      display: none;
    }

  }

  &__results {
    transition: opacity .3s ease;

    &.isLoading {
      opacity: .4;
    }

    .resources--number {
      color: $lt-gray;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
    }

    .cta.cta--newsletter-subscribe {
      margin: 4em 0;
    }
  }


  .current-location {
    display: block;
    font-size: 14px;
    margin-top: 3px;
    text-align: center;

    svg {
      fill: #009FD6;
      height: 14px;
      margin-right: 5px;
      width: 9px;
    }
  }

  .select-dd {
    position: absolute;
    right: 15px;
    bottom: 10px;
    pointer-events: none;

    svg {
      fill: #000;
      width: 12px;
      height: 12px;
    }
  }

}

#resources-browse {
  margin-bottom: 72px;

  .filter__results {
    .title {
      margin: 1em 0;
    }
  }

  .filter__no-results {
    background-color: $xlt-gray;
    margin: 0 auto;
    max-width: 1140px;
    padding: 64px 20px;
    text-align: center;

    @include mq(l) {
      border-radius: 16px;
    }

    h3 {
      color: $dk-gray;
      font-weight: 800;
      margin: 0 auto 18px auto;
      max-width: 352px;
    }

    p {
      color: $dk-gray;
      font-size: 14px;
      line-height: 23px;
      margin: 0 auto 25px auto;
      max-width: 352px;
    }

    a {
      @extend .button-outline;
    }
  }
}