.newsletter-signup {
  background-color: $dk-gray;
  display: flex;
  justify-content: center;
  padding: 70px 0;

  h2 {
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    @include mq(m) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__wrapper {
    width: 90%;
    text-align: center;
    @include mq(m) {
      width: 560px;
    }

    input[type=email] {
      display: inline-flex;
      background-color: transparent;
      border: 0 solid transparent;
      border-radius: 0;
      border-bottom: 2px solid #67686A;
      color: white;
      width: 90%;
      margin-right: 0;

      &::placeholder {
        color: white;
        font-style: normal;
      }
      @include mq(m) {
        width: 72%;
        margin-right: 3%;
      }
    }
    input[type=submit] {
      display: inline-flex;
      padding: 15px 30px;
      border-radius: 100px;
      border: 1px solid #FFFFFF;
      color: #fff;
      width: 80%;
      background-color: transparent;
      justify-content: center;
      margin-top: 45px;

      @include mq(m) {
        margin-top: 0;
        border: 0 solid transparent;
        color: $dk-gray;
        width: auto;
        background-color: white;
      }
    }
  }
}
