.share {
  cursor: pointer;
}

.share-modal {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, .2);
  left: 50%;
  opacity: 0;
  padding: 50px 20px;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  /*transition: all 500ms cubic-bezier(.645, .045, .355, 1);*/
  white-space: nowrap;
  width: 60%;
  z-index: 9;

  form {
    display: flex;

    input,
    .btn {
      height: 3.5em;
    }
    input {
      text-overflow: ellipsis;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    /*transform: translate3d(-50%, -50%, 0);*/
  }

  .modal-title {
    color: palette(branding, primary);
    font-size: 24px;
    font-weight: 300;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;

    svg {
      fill: palette(muted, light);
      transition: fill .3s ease;
    }

    &:hover {
      svg {
        fill: palette(muted, med);
      }
    }
  }
}

.share-overlay {
  background-color: rgba(0, 0, 0, .2);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 800ms;
  width: 100%;
  will-change: opacity;
  z-index: 9;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
