.link-blocks {
  @include angled-edge('outside top', 'upper right', #fff);
  padding: 0 0 2rem;

  @include mq(m) {
    padding: 0 0 5rem;
  }

  &__listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__item {
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, .4);
    display: block;
    margin-bottom: 1rem;
    margin-top: 0;
    padding: 3rem 2rem;
    position: relative;
    text-align: center;
    z-index: 1;

    @include mq(m) {
      margin-top: -4rem;
    }
    &:first-child {
      margin-top: -5rem;

      @include mq(m) {
        margin-top: -4rem;
      }
    }

    @include mq(m) {
      width: 48%;
    }

    span {
      color: #fff;
      display: inline-flex;
      font-style: italic;
      transition: all .3s ease;
    }

    .title {
      transition: all .3s ease;
    }

    svg {
      fill: #fff;
      height: 1.7em;
      width: 1.7em;
    }


    &:hover {
      text-decoration: none;

      span,
      svg,
      .title {
        color: darken(#fff, 10) !important;
        fill: darken(#fff, 10) !important;



        &::before {
          background-color: darken(#fff, 10) !important;
          width: 100px;
        }
      }
    }
  }
}