section.related {
  background-color: palette(muted, xlight);
  overflow: hidden;
  padding: 50px 0;
  clear: both;
}

.related-slider {
  margin-left: calc(-2rem - 8px);

  .flickity-viewport {
    overflow: visible;
  }

  .flickity-slider {
    margin-left: 10px;

    @include mq(m) {
      // margin: 50px;
    }
  }

  .carousel-cell,
  .resource-card {
    margin: 15px;
    min-height: 100%;
    width: calc(100vw - 20px);

    @include mq(m) {
      width: 40vw;
    }

    p {
      margin-bottom: 0;
    }

    img {
      max-height: 200px;
    }
  }
}