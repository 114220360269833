.util-bar {

  &.button {
    color: #fff!important;
  }

  &__inner {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    padding: 2rem 0 1rem;
  }

  ul {
    color: palette(muted, med);
    display: flex;
    font-family: $font1;
    font-size: 14px;
    font-weight: 500;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  li {
    &:not(:first-child) {
      margin-left: 1rem;

      &:before {
        background-color: palette(muted, light);
        content: '';
        display: inline-block;
        height: 1.2em;
        margin-bottom: -3px;
        margin-right: 1em;
        width: 1px;
      }
    }
  }

  svg {
    fill: palette(muted, med);
    height: 13px;
    margin-right: 5px;
    width: 13px;
  }

  a {
    color: palette(muted, med);
  }
}