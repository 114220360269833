.highest-rated-resources {
  background: #fefefe;
  padding: 0;

  &__listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(m) {
      flex-direction: row;
    }

    .resource-card {
      @include mq(m) {
        width: 48%;
      }
    }
  }

  &__link {
    text-align: center;
    margin: 1em 0;

    a {
      display: inline-flex;
      font-family: $font1;
      font-style: italic;
      font-weight: 700;
      line-height: 1.2em;
      transition: color .3s ease;
    }

    svg {
      fill: palette(branding, primary);
      height: 1.7em;
      margin-left: 4px;
      transition: fill .3s ease;
      width: 1.7em;
    }

    &:hover,
    &:focus {
      a {
        color: palette(muted, med);
      }

      // svg {
      //   fill: palette(muted, med);
      // }
    }
  }

  .flickity-prev-next-button[disabled] {
    display: none;
  }
}