.wrapper {
  margin: 0 auto;
  max-width: 1160px;
  padding-left: 16px;
  padding-right: 16px;

  &--full-width {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @include mq(xl) {
    max-width: 1640px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.section {
    margin-top: 3.4rem;
    /* &:first-child {
        margin-bottom: 3rem;
    } */
    &.has-background {
        &:first-child {
            margin-top: 0;
        }
        /* &:last-child {
            margin-bottom: 0;
        } */
        & + .section.has-background {
            margin-top: 0;
        }
    }
}

.main-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 16px;

    @include mq(l) {
      padding: 0 20px;
    }

    @include mq(ml) {
      padding: 0;
    }

    @include mq(xl) {
      max-width: 1640px;
      padding: 0 20px;
    }
}
