.quick-jumps {
    h3 {
        @extend .featured-section-title;
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            padding-left: 0;
            width: 100%;

            @include mq(l) {
                width: 170px;
            }

            @include mq(xxl) {
                width: 215px;
            }

            &:before {
                content: '';
            }

            p {
                color: $dk-gray;
                font-weight: normal;
                font-size: 14px;
                line-height: 163.7%;
            }

            a {
                color: $dk-gray;
                display: block;
                font-weight: 800;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 8px;
            }
        }
    }
}
