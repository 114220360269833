.title {
  margin-bottom: 40px;
  position: relative;

  &::before {
    background-color: #FFF;
    bottom: -18px;
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    transition: width .25s;
    width: 50px;
  }

  &--no-underline {
    margin-bottom: 20px;

    &::before {
      display: none;
    }
  }

  $title-colors: map-merge(map-get($palettes, branding), map-get($palettes, muted));
  @each $label, $color in $title-colors {
    &--#{$label},
    &--#{$label} a {
      color: $color !important;
      transition: color .3s ease;

      &::before {
        background: $color !important;
      }
    }
  }

  $title-colors: map-merge(map-get($palettes, branding), map-get($palettes, muted));
  @each $label, $color in $title-colors {
    &--#{$label} a {

      &:hover,
      &:focus {
        color: darken($color, 10) !important;
      }
    }
  }

  &--with-subtitle {
    &::before {
      bottom: -35px;
    }
  }

  &--create-collection-cat {
    font-size: 32px;
    font-style: italic;
    font-weight: 300;
    line-height: 1.2em;

    strong {
      font-weight: 700;
    }
  }

  &--centered {
    text-align: center;
    width: 100%;

    &::before {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &--left {
    text-align: left;
    width: 100%;
  }
}
