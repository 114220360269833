.featured-article {
  $root: &;
  margin: 5rem 0;

  &__inner {
    display: flex;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__content {
    padding-top: 3rem;

    @include mq(m) {
      width: 50%;
    }

    $title-colors: map-merge(map-get($palettes, branding), map-get($palettes, muted));
    @each $label, $color in $title-colors {
      &--#{$label} {

        h2 {
          color: $color;
        }

        #{$root}__meta {
          border-bottom: 2px solid $color;

          &__v {
            color: $color;
          }
        }

      }
    }

    h3 {
      color: palette(muted, med);
      font-size: 1rem;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    h2 {
      font-weight: 900;
    }

    p {
      color: palette(muted, med);
      padding-right: 20%;
    }
  }

  &__meta {

    color: palette(muted, med);
    font-family: $font1;
    font-weight: 700;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    &__k {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__image {
    margin-top: 2rem;

    @include mq(m) {
      margin-top: 0;
      width: 50%;
    }
  }
}