.resource-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0,0,0,.2);
  margin: 1.5rem 0;
  padding: 1.5rem 2rem 7rem;
  position: relative;
  transition: box-shadow .5s;

  &:hover {
    box-shadow: 0 3px 25px rgba(0, 0, 0, .25);

    .title {
      &::before {
        width: 100px;
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  time {
    color: #4D4D4D;
    font-size: 14px;
    margin-top: 2em;
  }

  p {
    margin: 1.5rem 0;
  }

  &.blog {
    padding-bottom: 1.7em;

    .resource-card__image {
      margin: 0 0 1em 0;

      @include mq(m) {
        float: left;
        margin: 0 2em 0 0;
      }
    }

    .title {
      &:before {
        display: none;
      }
    }

  }

  &__image {
    margin: 1em 0;
    width: 100%;

    @include mq(m) {
      margin: 0 0 1em 3em;
      float: right;
      width: 50%;
    }
    @include mq(l) {
      width: auto;
    }
  }

  &__details {
    align-items: center;
    border-top: 1px solid #ccc;
    bottom: 0;
    color: palette(muted, med);
    display: flex;
    flex-direction: column;
    font-family: $font1;
    font-weight: 500;
    margin-top: .5em;
    padding: 1.2em 0 2em;
    position: absolute;
    text-align: center;
    width: calc(100% - 4em);

    svg {
      fill: palette(muted, med);
      height: 16px;
      margin: 0 2px -3px 10px;
      width: 16px;
    }

    @include mq(m) {
      text-align: inherit;
      flex-direction: row;
    }

    .button {
      margin-bottom: 10px;
      margin-right: 10px;
      width: 100%;

      @include mq(m) {
        margin-bottom: inherit;
        width: auto;
      }
    }

    .util-bar {

      &__inner {
        border: none;
        padding: 0;
        margin: 0;
      }
    }

  }
}
