table {
  font-feature-settings: 'kern', 'liga', 'tnum';
  width: 100%;
  -ms-overflow-style: none; 
}

tr,
td,
th {
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

th {
  background-color: rgba(#aaa, .2);
  border-right: 1px solid #fff;
  color: palette(branding, primary);
  cursor: pointer;
  font-weight: 700;
  padding: 1em .7em;
  text-align: left;
  text-transform: uppercase;
}

td {
  color: palette(branding, secondary);
  font-weight: 500;
  padding: .5em .7em;
}

.wysiwyg {

  table {
    @include smoothing(false);
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 2em;
    overflow-y: scroll;
    display: block;

    th,
    td {
      border: 1px solid #ddd;
      padding: 5px 12px;
    }

    th {
      background: lighten(#eee, 2);
    }

  }
}