.image-grid {

  &__listing {
    display: flex;
    flex-wrap: wrap;

    @include mq(m) {
      justify-content: space-around;
      margin: 4em 0;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    padding: 1em;
    width: 50%;

    @include mq(m) {
      width: 25%;
    }

    a {
      display: block;

      &:hover,
      &:focus {
        opacity: .8;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}