.comparison {
  border-top: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 4em;

  h6 {
    text-transform: uppercase;
    &:before {
      display: none;
    }
  }

  em {
    display: inline-block;
    font-size: smaller;
    width: 100%;
  }

  img {
    width: auto;
    max-width: 100%;
  }
  
  tr {
    th, td {
      white-space: normal;
      border: none;
      border-bottom: 1px solid black;
      padding: 0 5px;
    }

    th {
      text-align: center;
      background-color: palette(branding, primary);
      color: black;
      height: 26px;
    }

    td:not(:first-child) {
      text-align: center;
    }

    &.align-left td {
      text-align: left;
    }
  }

  img.question-image {
    width: 16px;
    float: none;
    margin-left: 2px;
  }
}

.lighting-comparison {
  width: calc(100% - 5px);
  border-top: none;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15), 0px 0px 9px rgba(0,0,0,.11);

  tr {
    &:nth-child(even) {
      background-color: rgba(243,243,243,1);
    }

    &:last-child td {
      margin-bottom: 11px;
    }

    &.spacer {
      height: 11px;
    }

    td {
      font-size: 16px;
      vertical-align: top;
      width: 25%;
      text-align: left !important;
      padding: 12px;
      border-right: 2px solid #ebebeb;
      border-bottom: none;

      &:last-child {
        border-right: none;
      }
    }
  }

  tbody img {
    margin: 0;
  }

  .comparison-header img {
    margin-bottom: 2rem;
  }
}

.wysiwyg table.lighting-comparison {
  display: table;
  overflow-y: auto;
}

.entry-detail__body .comparison img {
  float: none;
}

.share-modal.comparison-modal {
  max-height: 80%;
  overflow: scroll;
  white-space: normal;
  text-align: left;
  padding-bottom: 20px;
  
  p {
    white-space: normal;
  }

  table img {
    width: 100%;
    margin: 15px 0;
  }

  .modal-title {
    display: block;
    margin-bottom: 1em;
  }

  .modal-close {
    color: transparent;
    background: url(/static/img/icons/close.png) no-repeat;
    background-size: cover;
    height: 13px;
    margin-right: 5px;
    width: 13px;
    transition: background-image .3s ease;

    &:hover {
      background-image: url(/static/img/icons/close-hover.png);
    }
  }
}

.entry-lighting-comparison-tool, .entry-led-replacements-for-hid-lighting {
  .entry-detail__embed {
    height: auto;
    padding-bottom: 0;
  }
}

.embed-copy {
  svg {
    fill: palette(branding, primary);
    height: 1.7em;
    margin-left: 4px;
    transition: fill .3s ease;
    width: 1.7em;
  }

  .embed-code {
    margin-top: 20px;
    display: none;

    &.active {
      display: block;
    }
  }
}

.iframe-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tbody--primary {
  td:first-child {
    border-left: 8px solid palette(branding, primary);
  }
}

.tbody--danger {
  td:first-child {
    border-left: 8px solid palette(branding, danger);
  }
}

.tbody--info-alt {
  td:first-child {
    border-left: 8px solid palette(branding, info-alt);
  }  
}