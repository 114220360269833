.sub-category {

  &__inner {
    margin: 3rem 0;
  }

  &__listing {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    border-radius: 5px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1), 0 2px 10px rgba(0, 0, 0, .2);
    font-family: $font1;
    margin: 1.5rem 0;
    padding: 1.5rem 2rem 6rem;
    position: relative;
    transition: box-shadow .25s;
    width: 30%;

    .title {
      font-size: 25px;
      font-weight: 300;

      @include mq(l) {
        font-size: 32px;
      }
    }

    &:hover {
      box-shadow: 0 3px 25px rgba(0, 0, 0, .35);

      .sub-category__count {
        background: linear-gradient(to top right, palette(branding, info), lighten(palette(branding, info), 15));
      }

      .title {
        &::before {
          width: 100px;
        }
      }
    }
  }

  &__count {
    background-color: palette(branding, info);
    border-radius: 4px;
    color: #FFF;
    float: right;
    font-size: 14px;
    padding: 0 8px;
  }

  li {
    color: palette(muted, light);
    font-size: 23px;
    font-weight: 300;
    line-height: 1.4em;
    padding-bottom: .4em;

    &:not(:first-child) {
      border-top: 1px solid palette(muted, light);
      padding-top: .4em;
    }

    a {
      color: palette(muted, med);
      display: block;
      width: 100%;
    }
  }

  &__details {
    border-top: 1px solid #ccc;
    bottom: 0;
    color: palette(muted, med);
    display: inline-block;
    font-family: $font1;
    font-size: 14px;
    font-weight: 300;
    padding: 1.2em 0 1.5em;
    position: absolute;
    width: calc(100% - 4em);
  }

  &--solutions {
    background-color: palette(muted, xlight);
    padding: 1px;

    .sub-category {

      &__listing {
        flex-wrap: wrap;
      }

      &__item {
        background: #FFF;
        border: 2px solid palette(branding, info);
        box-shadow: 0 0 35px rgba(0,0,0,.2);
        width: 100%;

        @include mq(m) {
          width: 31%;
        }
      }
    }
  }

}