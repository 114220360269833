.cta {
  $root: &;
  background: palette(branding, info);
  background-size: cover;
  background-position: center center;
  color: #fff;
  text-align: center;
  .title--text-shadow {
    text-shadow: 1px 1px 2px #000000;
  }
  .title--text-white {
    color: #fff;
  }
  .title--text-black {
    color: #000000;
  }
  &__inner {
    padding: 2rem 0 1rem;

    @include mq(m) {
      /* padding: 4rem 20rem 1rem 0; */
      padding: 4rem 11rem 1rem;
    }
  }

  p {
    color: #fff;
  }

  a {
    color: #fff;
  }

  &__search,
  &__subscribe,
  &__copy {
    margin-bottom: 2rem;

    form {
      align-items: center;
      display: flex;
      flex-direction: column;

      input {
        height: 3em;
      }

      @include mq(m) {
        flex-direction: row;
      }
    }
  }

  &__copy {
    padding: 0 8%;

    input {
      color: palette(muted, light);
      font-style: italic;
    }
  }


  &__subscribe {
    margin-top: 2rem;

    @include mq(m) {
      padding: 0 20%;
    }
  }

  &__link {
    a {
      display: inline-flex;
      font-family: $font1;
      font-style: italic;
      font-weight: 700;
      line-height: 1.2em;
      transition: color .3s ease;
    }

    svg {
      fill: #fff;
      height: 1.7em;
      margin-left: 4px;
      transition: fill .3s ease;
      width: 1.7em;
    }

    &:hover,
    &:focus {
      a {
        color: #ddd;
      }

      svg {
        fill: #ddd;
      }
    }
  }

  &--heading-only,
  &--heading-and-button {

    #{$root}__inner {
      @include mq(m) {
        padding: 5rem 15% 4rem;
      }
    }
  }

  &--resource-landing {

    #{$root}__inner {
      @include mq(m) {
        padding: 5rem 20% 4rem;
      }
    }
  }

  &--solutions {

    #{$root}__inner {
      @include mq(m) {
        padding: 8rem 15% 6rem;
      }
    }

    .title {
      margin-bottom: 20px;

      &::before {
        display: none;
      }
    }
  }

  &--resource-search {
    @include angled-edge('inside bottom', 'upper right', #fff);
    background-image: url('/static/img/ctas/woman-laptop-green.jpg');
    background-size: cover;
    padding-bottom: 5rem;

    #{$root}__inner {

      @include mq(m) {
        padding: 5rem 20%;
      }
    }

    .title {
      margin-bottom: 20px;

      &::before {
        display: none;
      }
    }
  }


  &--no-slant {
    padding-bottom: 0;

    &:after {
      display: none;
    }

    .cta__link {
      margin-bottom: 0;
    }
  }

  &--newsletter-subscribe {
    background-image: url('/static/img/ctas/man-typing-laptop-with-coffee-dark.jpg');
    background-position: center center;
    background-size: cover;
    padding: 2rem 0;

    #{$root}__inner {
      padding: 3rem 0 2rem;
    }

    #{$root}__subscribe {
      margin: 0;
    }

    h2 {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.3em;

      strong {
        font-weight: 700;
      }
    }

    .title {
      margin-bottom: 20px;

      &::before {
        display: none;
      }
    }

    .e2ma_signup_form_label,
    #e2ma_signup_reset_button,
    .e2ma_signup_form_required_footnote {
      position: absolute;
      visibility: hidden;
    }

    #id_email {
      @include mq(m) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    #e2ma_signup_submit_button {
      background-color: palette(branding, info);
      padding: 0 2em;
      margin-top: 1em;

      &:hover,
      &:focus {
        background-color: darken(palette(branding, info), 10);
      }

      @include mq(m) {
        margin-top: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .e2ma_signup_form_row {
      width: 90%;

      @include mq(m) {
        width: 75%;
      }
    }


  }

  &--offset-content {
    background-image: url('/static/img/ctas/people-smiling-at-computer-screen-blue.jpg');
    background-position: center center;
    background-size: cover;
    padding-bottom: 5rem;
    text-align: left;
    .cta__inner {
      padding: 2rem 0 1rem;
      @include mq(m) {
            padding: 4rem 20rem 1rem 0;
      }
    }

    @include mq(m) {
      padding: 5rem 0 12rem 0;
    }
  }

  &--offset-with-image {
    background: transparent;
    border-bottom: 2px solid #ddd;
    padding: 3rem 0;
    text-align: left;

    p {
      color: palette(muted, dark);
    }

    #{$root} {

      &__image {
        margin-bottom: 1em;
        width: 100%;

        @include mq(m) {
          float: right;
          margin-left: 3em;
          width: auto
        }

        img {
          width: 100%;
        }
      }

      &__inner {
        padding: 1em 0;

        @include mq(m) {
          padding: 2rem 0;
          width: 100%;
        }
      }
    }

  }

  &--create-collection {
    background-position: center center;
    background-size: cover;
    text-align: left;

    #{$root} {

      &__image {
        align-items: flex-end;
        display: flex;
        margin-top: 2rem;

        @include mq(m) {
          margin-top: 0;
          width: 46%;
        }
      }

      &__body {
        padding: 2rem 0 1rem;

        @include mq(m) {
          padding: 6rem 0 4rem;
          width: 48%;
        }
      }

      &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;

        @include mq(m) {
          flex-direction: row;
        }
      }
    }
  }


}