// sass-lint:disable variable-name-format

input,
label,
select {
  display: block;
}

label {
  color: palette(branding, primary);
  margin-bottom: .5em;

  &.required {
    ::after {
      content: '*';
    }
  }

  abbr {
    display: none;
  }

  &.error {
    color: palette(status, error);;
    margin-top: .25em;
  }
}

button {
  background: transparent;
}

#{$all-text-inputs},
select {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #000;
  display: block;
  font-family: $font1;
  font-size: 1em;
  max-width: 100%;
  padding: .75em 1em;
  transition: all .3s ease;
  width: 100%;

  &::placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: 300;
    line-height: 1.4em;
  }

  &.-has-addon {

    padding-right: 0;

    +[type='submit'],
    +.form-button {
      margin-top: .5rem;
      padding: .75rem 2rem;
    }

    @include mq(m) {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;

      +[type='submit'],
      +.form-button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-top: 0;
      }
    }
  }

  &.error {
    border: 1px solid palette(status, error);
  }

  &:hover {
    border-color: #aaa;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.select {
  position: relative;

  &:before {
    content: "";
    display:block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #9B9B9B;
    position: absolute;
    top: 50%;
    left: .8em;
    transform: translateY(-50%);
  }
  select {
    $color: #ddd;
    @include smoothing(false);
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: inset .5px .5px 0 #000, inset -.5px -.5px 0 #000;
    color: #9B9B9B;
    cursor: pointer;
    display: inline-block;
    font-family: $font1;
    font-size: 16px;
    font-weight: 500;
    padding: .5em .7em .5em 2em;
    text-decoration: none;
    transition: all .1s ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid $color;
    // Hack for 1.5px borders that we probably shouldn't do
    box-shadow: inset .5px .5px 0 $color, inset -.5px -.5px 0 $color, inset .5px -.5px 0 $color, inset -.5px .5px 0 $color;

  }
}

.form-button {
  align-items: center;
  background: palette(branding, primary-alt);
  border: 0;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: $font1;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0 1.4em;
  text-transform: uppercase;

  svg {
    fill: #FFF !important;
    margin: 0 10px 0 0 !important;
  }

  @each $label, $color in map-get($palettes, branding) {
    &.-#{$label} {
      background: palette(branding, $label);
      border: 1px solid palette(branding, $label);

      &:hover {
        background: lighten(palette(branding, $label), 3%);
        border: 1px solid lighten(palette(branding, $label), 3%);
      }
    }
  }
}

input {

  &[type='submit'] {
    @extend .form-button;
  }


  &[type='checkbox'],
  &[type='radio'] {
    display: inline;
    margin-right: .5em;

    + label {
      display: inline-block;
    }
  }

  &[type='file'] {
    margin-bottom: 1em;
    outline: 0;
    width: 100%;
  }

  &.small {
    padding: .45rem .7rem;
    border-radius: 8px;
  }

  &.header__search {
    background: url('/static/img/icons/search-blue.svg') center center no-repeat;
    background-size: 25px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 25px;
    height: 25px;
    padding: 12px;
    position: relative;

    &::placeholder {
      color: #fff;
      font-style: normal;
      font-weight: 400;
      opacity: 1;
    }

    &:active,
    &:focus {
      background-color: #FFF;
      background-image: url('/static/img/icons/search-blue.svg');
      background-repeat: no-repeat;
      background-position: 98%;
      color: #000;
      cursor: inherit;
      width: 200px;
      transition: none;

      &::placeholder {
        color: palette(muted, light);
        font-style: italic;
        text-transform: none;
      }
    }
  }
}

textarea {
  resize: vertical;
}

.control {
  cursor: pointer;
  position: relative;
  padding-left: 1.8em;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &:hover,
  &:active {
    .control-indicator {
      background-color: #fafafa;
      border:1px solid #aaa;
    }
  }
}

.control-indicator {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
  border-radius: 2px;
  border: 1px solid #ccc;
  display: block;
  height: 19px;
  left:0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 19px;
}

.checkbox {
  input:checked + .control-indicator {
    background-image:url('/static/img/icons/checkbox-checked.svg');
  }
}

button[type='submit'] {
  @extend .form-button;
  padding: 1em 2em;
}
