.slider {
  $root: &;
  @include angled-edge('inside top', 'lower left', palette(branding, info));
  background-size: cover;
  margin-top: 0;
  margin-bottom: 0;
  // For prefixed angled div
  padding-bottom: 2rem;
  padding-top: 9rem;
  position: relative;
  transition: background-image .5s ease;
  z-index: 0;
  &__inner {
    margin: 5vh 0;

    // Selected outline
    &::after {
      border: 2px solid palette(branding, danger);
      border-radius: 10px;
      content: '';
      display: none;
      height: 100%;
      left: 33%;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 33%;

      @include mq(590px) {
        display: block;
        width: 50%;
        left: 24%;
      }
      @include mq(l) {
        display: block;
        width: 33%;
        left: 33%;
      }
    }

  }

  &__item {
    color: palette(muted, med);
    cursor: pointer;
    display: block;
    transition: color .3s ease, border-color .3s ease, padding .3s ease;
    padding: 60px 40px 0;
    width: 100%;
    @include mq(590px) {
      padding: 60px 20px 0;
      width: 50%;
    }
    @include mq(l) {
      width: 33%;
    }

    h2 {
      margin-bottom: 20px;
      font-size: 26px;
      @include mq(l) {
          font-size: 40px;
          line-height: 48px;
      }
    }

    p {
      color: palette(muted, med);
      font-weight: 500;
      margin-bottom: 1.5em;
    }

    a {
      align-items: center;
      color: palette(muted, med);
      display: inline-flex;
      font-style: italic;
      font-weight: 700;
      opacity: 0;
      transition: all .3s ease;

      svg {
        fill: palette(branding, danger);
        height: 1.7em;
        width: 1.7em;
      }
    }

    &.is-selected {
      color: palette(branding, danger);
      padding-top: 20px;

      p {
        color: palette(branding, danger);
      }

      #{$root}__icon {
        border-bottom-color: palette(branding, danger);

        svg,
        path {
          fill: palette(branding, danger);
        }
      }

      a {
        color: palette(branding, danger);
        opacity: 1;

        &:hover,
        &:focus {
          color: darken(palette(branding, danger), 10);

          svg {
            fill: darken(palette(branding, danger), 10);
          }
        }
      }
    }
  }

  &__icon {
    align-items: center;
    border-bottom: 2px solid palette(muted, med);
    display: flex;
    height: 150px;
    margin-bottom: 30px;
    width: 33%;

    svg,
    path {
      fill: palette(muted, med);
      width: 80%;
    }
  }

  .flickity-prev-next-button {
    align-items: center;
    background: none;
    border-radius: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    &.previous {
      left: -2vw;
    }

    &.next {
      right: -2vw;
    }

    svg {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;

      .arrow {
        fill: palette(muted, med);
        transition: fill .3s ease;
      }
    }

    &:hover,
    &:focus {
      background: none;
      outline: none;

      svg {
        .arrow {
          fill: palette(muted, dark);
        }
      }
    }
  }
}

.highest-rated-resources {
  margin-top: 0;
}

.fr-slider {
  .wrapper {
    @media (max-width: 600px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slider__inner::after {
    display: none;
  }

  .slider__item {
    @media (max-width: 600px) {
      padding: 40px 1.5rem;
    }

    .resource-card {
      @media (min-width: 768px) {
        margin-top: 0;
      }

      .title {
        font-size: 1.7em;
        line-height: 1.3;
      }

      p {
        max-height: 130px;
        overflow: hidden;
      }
    }

    a {
      opacity: 1;
    }

    &.is-selected {

      p {
        color: #6C6E6C;
      }
    }

    &:not(.is-selected) {
      h4 {
        a {
          color: #6C6E6C !important;
        }
      }
    }

    .rating {
      @media (min-width: 46.25em) {
        display: block;
      }
    }
  }
}