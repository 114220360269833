.solution-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0,0,0,.2);
  margin: 1.5rem 0;
  padding: 1.5rem 2rem 3rem;
  position: relative;
  transition: box-shadow .5s;

  &:hover {
    box-shadow: 0 3px 25px rgba(0, 0, 0, .25);

    .title {
      &::before {
        width: 100px;
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  p {
    margin: 2.5rem 0 2rem;
  }

  &__initiatives-intro {
    display: flex;
    margin: 25px 0;

    hr {
      flex-grow: 1;
      margin: 10px 0;
    }

    span {
      border-radius: 5px;
      box-shadow: 0 0 15px rgba(0, 0, 0, .2);
      color: palette(muted, med);
      font-family: $font1;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 12px;
      margin-right: 10px;
      padding: 4px 8px;
    }
  }

  &__initiatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0 -30px;

    a {
      color: palette(branding, primary);
      font-family: $font1;
      font-size: 22px;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;

      @include mq(m) {
        margin-bottom: 30px;
        width: 32%;
      }

      img {
        margin: 0 0 10px;
        width: 100%;
      }

      &.dummy {
        visibility: hidden;
      }
    }

  }

  &__border {
    border-top: 1px solid #CFCFCF;
    padding: 40px 0;
    margin-bottom: 0;
    width: 100%;

    &:last-of-type {
      border-bottom: 1px solid #CFCFCF;
      margin-bottom: 62px;
    }
  }

}
