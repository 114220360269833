.form {
  font-family: $font1;
  margin-bottom: 20px;
  margin-top: 20px;


  .title {
    margin: 50px 0;
  }

  .lede {
    padding: 0 10%;
  }


  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__form {
    margin-bottom: 30px;
    width: 100%;

    @include mq(m) {
      flex: 3;
    }

    @include mq(l) {
      flex: 4;
    }

    label {
      font-weight: 500 !important;
    }

    &__thanks {
      background-color: palette(muted, xlight);
      height: 100%;
      margin: 0 0 20px;
      padding: 10%;
      text-align: center;
    }
  }

  .freeform-column {
    width: calc(100% - 30px);
  }

  .form__methods {

    p {
      font-size: 14px;
      line-height: 1.5em;
    }

    @include mq(m) {
      flex: 2;
      margin-left: 5%;
    }
  }

}