.blog-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0,0,0,.2);;
  margin: 2rem 0;
  padding: 1.5rem 2rem 2rem;
  position: relative;
  transition: box-shadow .5s;

  &:hover {
    box-shadow: 0 3px 25px rgba(0, 0, 0, .25);
  }

  img {
    float: left;
    margin-right: 2em;
  }

  time {
    color: palette(muted, med);
    font-size: 14px;
  }

  .title {
    font-size: 36px;
  }

  p {
    margin: 0 0 1rem;
  }
}
