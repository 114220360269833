.header {
  // background-color: palette(branding, info);
  position: relative;
  width: 100%;
  z-index: 9;

  .inverted-header & {
    box-shadow: none;
  }

  @include mq(m) {
    background: none;
  }

  &__utils {
    @include smoothing(false);
    background: $dk-blue;
    display: none;

    @include mq(m) {
      display: block;
    }

    &__inner {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 10px 0;

      @include mq(m) {
        justify-content: flex-end;
      }

      .header__search {
        background: url('/static/img/icons/search-white.svg') 1px 6px no-repeat;
        background-size: 14px;
        font-size: 14px;
      }
    }

    ul {
      display: flex;
      margin: 0 -11px 0 0;
    }

    li {
      margin-right: 1.5em;
    }

    a {
      color: #fff;
      display: flex;
      font-family: $font1;
      font-size: 12px;
      letter-spacing: 1px;

      @include mq(l) {
        font-size: 14px;
        line-height: 23px;
      }

      svg {
        fill: #fff;
        height: 13px;
        margin-right: 8px;
        margin-top: 5px;
        width: 13px;
      }
    }
  }

  &__main {

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 35px 0;

      @include mq(m) {
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
      }
      @include mq(l) {
        flex-direction: row;
        padding: 50px 0;
      }

      form {
        height: auto;
        width: 25px;

        &.search {
          @media (min-width: 740px) {
            display: none;
          }

          .header__search {
            right: 16px;
            position: absolute;
            transition: none;
            top: 35px;

            &:active,
            &:focus {
              background-size: 20px;
              border: 1px solid #CFCFCF;
              height: 50px;
              width: 75%;
              right: 16px;
              top: 25px;
            }
          }
        }
      }
    }
  }

  &__burger {
    color: $dk-blue;
    font-size: 28px;
    height: 25px;
    transition: opacity .2s ease;
    width: 25px;

    .inverted-header & {
      color: #fff;
    }

    @include mq(m) {
      display: none;
    }

    .nav-open & {
      opacity: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
