// Resource button
.resource-block__view-all-white a {
  padding: 15px 30px;
}

.zip-input {
  /*background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
  border-bottom-color: #0a0a0a !important;*/
  background-color: #f8f8f8 !important;
  outline: 0 !important;
  border-width: 0 0 2px !important;
  border-color: #0a0a0a !important;
  border-radius: 0 !important;
}
