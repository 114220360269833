.entry-detail {

  .wrapper {
    max-width: 1160px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__heading {

    @include mq(m) {
      margin: 2rem 0 0;
    }
    @include mq(l) {
      margin: 2rem 0 0 10%;
    }

    .resource-type {
      margin-top: -1em;
    }

    .toc-dropdown {
      margin: 15px 0;
      @include mq(m) {
        display: none;
      }
    }
  }

  &__inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include mq(m) {
      display: block;
      flex-direction: row;
      padding: 1rem 0 2em;
    }

    @include mq(l) {
      padding: 1rem 0 2em 10%;
    }
  }

  &__sidebar {

    @include mq(m) {
      width: 260px;
      float: right;
      display: block;
      padding:0 5px 2em 2em ;
    }
  }

  &__body {
    order: -1;
    overflow: hidden;
    transition: max-height 1500ms;

    @include mq(m) {
      padding-right: 3rem;
      width: auto;
    }
    table img {
      width: auto;
    }
    img {
      margin: 0 0 2rem;
      width: 100%;

      @include mq(m) {
        width: auto;
      }
      @include mq(l) {
        float: left;
        margin: 0 2rem 2rem 0;
      }
    }


    h3 {
      color: palette(muted, med);
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 1em;
    }
  }

  &__embed {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    margin-bottom: 30px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    &.align-center {
      float: none;

      &.width-half {
        @include mq(m) {
          margin: 0 25% 2rem;
        }
      }

      &.width-quarter {
        @include mq(m) {
          margin: 0 37.5% 2rem;
        }
      }

      &.width-full {
        @include mq(m) {
          margin: 0 0 2rem;
        }
      }
    }

    &.align-right {
      float: right;
      margin: 0 0 2rem 2rem;
    }

    &.align-left {
      float: left;
      margin: 0 2rem 2rem 0;
    }

    &.width-half {
      width: 100%;

      @include mq(m) {
        width: 50%;
      }
    }

    &.width-quarter {
      @include mq(m) {
        width: 25%;
      }
    }

    &.width-full {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  &__continue-reading {
    border-top: 1px solid palette(muted, light);
    padding: 20px 0;
    margin: 20px 0;
    text-align: center;
    &.desktop {
      display: none;
      @include mq(m) {
        display: block;
      }
    }
    &.mobile {
      display: block;
      @include mq(m) {
        display: none;
      }
    }
  }

}
