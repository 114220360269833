.rating {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .sr-avg-rating {
    color: #e5cf4b;
  }

  .rating-popup {
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0 2px 20px rgba(0,0,0,.2);
    display: none;
    padding: 10px 20px;
    position: absolute;
    text-align: center;
    top: 30px;
    white-space: nowrap;
    width: 100%;
    z-index: 9;

    &.active {
      display: block;
    }
  }
}