.content-with-embed {

  &__inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    @include mq(m) {
      flex-direction: row;
    }

    > div {
      @include mq(m) {
        width: 45%;
      }
    }

  }

  &__embed {
    height: auto;
  }
}