.article {
  padding-bottom: 3rem;
  position: relative;
  z-index: 2;

  .cf {
    clear: both;
  }

  &__inner {
    background: #fff;
    margin-top: 55px;
    min-height: 500px;
    position: relative;
  }

  &__intro {
    padding: 3rem 5%;
    z-index: 3;

    @include mq(m) {
      padding: 5rem 5% 3rem;
    }

    .lede {
      padding: 0 15%;
      text-align: center;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
    }

    &::before {
      box-shadow: 0 -5px 20px rgba(#000, .2);
      height: 400px;
      width: 100%;
      z-index: -2;
    }

    // &::after {
    //   background: linear-gradient(transparent, #fff 80%);
    //   display: block;
    //   height: calc(400px + 30px);
    //   margin: 0 -30px;
    //   width: calc(100% + 60px);
    //   z-index: -1;
    // }
  }

  &__body {

    @include mq(m) {
      padding: 0 10%;
    }

    h3 {
      color: palette(branding, danger);
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 1em;
      position: relative;

      &::after {
        background-color: palette(branding, danger);
        bottom: -10px;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        width: 50px;
      }

      & + figure {
        margin-top: -4.5rem;
      }
    }

    // TODO: This is a pretty fragile fix for the underline problem at http://up.chri.sr/Screen-Shot-2017-11-07-17-35-51-C8cjN2nbGM.png
    figure.left + h3::after {
      left: 55%;
    }

  }

}

body.slug-newsletter-signup {
  .article__intro {
    padding-bottom: 0;

    p.lede {
      margin-bottom: 0;
    }
  }
}