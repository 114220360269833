.resource-type {
  color: palette(muted, light);
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;

  svg {
    fill: palette(muted, light);
    height: 12px;
    margin-bottom: -1px;
    margin-right: 5px;
    width: 18px;
  }
}