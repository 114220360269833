.body--submitResource {
  font-family: $font1;

  .title {
    margin: 50px 0 25px;
  }

  .lede {
    padding: 0 10%;
  }

  .resource {
    margin-bottom: 20px;
    margin-top: 20px;

    @include mq(m) {
      padding: 0 15%;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;

      .resource__form {
        margin-bottom: 30px;
        width: 100%;

        label {
          font-weight: 500 !important;
        }

        &__thanks {
          background-color: palette(muted, xlight);
          height: 100%;
          margin: 0 0 20px;
          padding: 10%;
          text-align: center;
        }
      }

    }
    // Freeform style overrides
    .freeform-column {
      flex-basis: 100%;
      margin: 0;
    }
  }


}