.footer {
  @include smoothing(false);
  width: 100%;
  background-color: $gray;
  color: palette(muted, white);
  font-family: $font1;
  font-weight: 500;
  padding: 44px 0;

  .wrapper {
    @include mq(m) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__nav {
    margin-bottom: 15px;
    width: 100%;
  }

  li {
    display: block;
    margin-bottom: 29px;

    &:not(:last-child) {
      margin-right: 1.5em;
    }

    @include mq(m) {
      display: inline;
    }
  }

  a {
    color: palette(muted, white);
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  small {
    display: inline;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 23px;
    margin-bottom: 20px;
    text-transform: none;

    @include mq(m) {
      font-size: 12px;
      line-height: 22px;
    }

    span {
      display: block;

      @include mq(m) {
          display: inline;

        &:after {
          content: " | ";
        }

        &:last-of-type {
          &:after {
            content: "";
          }
        }
      }
    }

    a {
      @extend small;
    }
  }

  .footer-logo {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;

    @include mq(m) {
      display: flex;
      flex: 1 0 auto;
      margin-top: 0;
      justify-content: flex-end;
    }

    img {
      height: 42px;
      width: 146px;
    }
  }
}
