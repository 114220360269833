.section-search {
  $root: &;
  background: palette(branding, primary);
  background-image: url('/static/img/ctas/woman-laptop-green.jpg');
  background-size: cover;
  color: #fff;
  text-align: center;

  &__inner {
    padding: 3rem 0;

    @include mq(m) {
      padding: 5rem 5%;
    }
  }

  .title {
    color: #fff;
  }

  p {
    color: #fff;
    font-family: $font1;
    font-size: 21px;

    @include mq(m) {
      padding: 0 10%;
    }
  }

  a {
    color: #fff;
  }

  .current-location {
    font-family: $font1;
    text-decoration: underline;
  }

  &__form {
    margin-bottom: 2rem;
    padding: 0 15%;

    form {
      align-items: center;
      display: flex;
      flex-direction: column;

      input {
        height: 3em;
      }

      @include mq(m) {
        flex-direction: row;
      }
    }
  }

  svg {
    fill: #fff;
    height: 14px;
    margin-right: 8px;
    width: 9px;
  }

}
