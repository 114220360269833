.nav-main {
  display: none;

  @include mq(m) {
    display: block;
    margin-top: 2rem;
  }
  @include mq(l) {
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0;

    @include mq(m) {
      flex-direction: row;
      margin: 2px 0 0 0;
    }
  }

  li {
    text-align: center;

    &:not(:first-child) {
      @include mq(m) {
        margin-left: 1em;
      }
      @include mq(l) {
        margin-left: 1.5em;
      }
    }

    &.active {
      a {
        .inverted-header & {
          border-bottom: 2px solid #fff;
        }
      }
    }
  }

  a {
    border-bottom: 4px solid transparent;
    color: $dk-gray;
    font-family: $font1;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    transition: border .3s ease;

    .inverted-header & {
      color: #fff;
    }

    &:hover,
    &:focus {
      text-decoration: none;

      .inverted-header & {
        border-bottom: 2px solid rgba(#fff, .4);
      }
    }
  }

  .nav {
    li {
      > a {
        &:hover {
          &:after {
            border-bottom: 4px solid $dk-green;
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            z-index: 1;
          }
        }
      }

      &.active {
        > a {
          &:after {
            border-bottom: 4px solid $dk-green;
            content: '';
            display: block;
            position: absolute;
            width: 25px;
            z-index: 1;
          }

          &:hover {
            &:after {
              transition: width .1s linear;
              width: 100%;
            }
          }
        }
      }
    }

    .submenu {
      background: #FFFFFF;
      border: 1px solid rgba(127, 190, 211, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
      display: block;
      padding: 25px 20px;
      position: absolute;

      @media (min-width: 1200px) {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
        max-width: 580px;
        left: -122px;
      }

      li {
        margin-bottom: 17px;
        width: 135px;
        text-align: left;

        @media (min-width: 1200px) {
          margin-right: 68px;
        }

        &.active {
          a {
            &:after {
              border: none;
            }
          }
        }

        a {
          border: none;
          color: $dk-gray;
          font-size: 14px;
          font-weight: normal;
          line-height: 163.7%;
          text-transform: none;

          &:hover,
          &:active {
            &:after {
              border-bottom: none;
            }
          }
        }

        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}
