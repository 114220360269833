.sr-only {
	border:0;
	clip:rect(0,0,0,0);
	height:1px;
	margin:-1px;
	overflow:hidden;
	padding:0;
	position:absolute;
	width:1px;
}

.white-space-nowrap {
	white-space:nowrap;
}

.hidden {
	display:none!important;
}

.display-block {
	display:block!important;
}

.display-inline {
	display:inline!important;
}

.display-inline-block {
	display:inline-block!important;
}

.img-responsive {
	display:block;
	height:auto;
	max-width:100%;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
 .cf:before,
 .cf:after {
     content: " "; /* 1 */
     display: table; /* 2 */
 }

 .cf:after {
     clear: both;
 }

 /**
  * For IE 6/7 only
  * Include this rule to trigger hasLayout and contain floats.
  */
 .cf {
     *zoom: 1;
 }

