.category-card {
  $root: &;
  border: 3px solid palette(branding, info);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  margin: 1.5rem 0;
  padding: 1.5rem 2rem 5rem;
  position: relative;
  transition: box-shadow .25s;
  flex: none;
  flex-wrap: wrap;
  width: 100%;

  @include mq(m) {
    width: 47%;
  }

  @include mq(l) {
    width: 23%;
  }

  @include mq(l) {
    padding: 1rem 1.5rem;
  }
  @include mq(xl) {
    padding: 1.5rem 2rem;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &:hover {
    box-shadow: 0 3px 25px rgba(0, 0, 0, .45);
    text-decoration: none;

    #{$root}__count {
      background: linear-gradient(to top right, palette(branding, info), lighten(palette(branding, info), 15));
    }

    .title {
      &::before {
        width: 100px;
      }
    }
  }

  .title {
    font-weight: 300;

    @include mq(l) {
      font-size: 28px;
    }
    @include mq(xl) {
      font-size: 32px;
    }
  }

  p {
    margin: 2rem 0 4rem;
  }

  &__details {
    border-top: 1px solid #ccc;
    bottom: 0;
    color: palette(branding, info);
    display: inline-block;
    font-family: $font1;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 1em 0 1.5em;
    position: absolute;
    text-transform: uppercase;
    width: calc(100% - 4em);
  }

  &__count {
    background: palette(branding, info);
    border-radius: 4px;
    color: #FFF;
    padding: 2px 5px;
  }


}