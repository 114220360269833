::selection {
  background: lighten(palette(branding, primary), 30);
  color: #444;
  text-shadow: none;
}

html {
  background-color: palette(base, body);
  box-sizing: border-box;
  min-height: 100%;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  color: $dk-gray;
  font-family: $font1;
  font-feature-settings: 'kern', 'liga', 'pnum';
  font-size: $base-font-size;
  line-height: $base-line-height;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dk-gray;
  font-family: $heading-font-family;
  font-size: $base-font-size * 1.2;
  line-height: $heading-line-height;
  margin: 0 0 .2em;
}

h1 {
  font-size: 42px;
  font-weight: 800;
  line-height: 133%;
}

h2 {
  font-size: 32px;
  font-weight: 800;
  line-height: 130%;
}

h3 {
  font-size: 18px;
  font-weight: 800;
  line-height: 140%;
}

h4 {
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
}

h5 {
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
}

h6 {
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
}

.p1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 164%;
}

.tag {
  color: $dk-gray;
  font-size: 14px;
  font-weight: 800;
  line-height: 149%;
}

ul,
ol,
p {
  color: $dk-gray;
}

p {
  @include smoothing(false);
  margin: 0 0 30px;

  &.lede {
    font-family: $font1;
    font-size: 18px;
    line-height: 30px;

    @include mq(m) {
      font-size: 21px;
      line-height: 32px;
    }
  }
}

.lede--centered {
  text-align: center;
}

a {
  color: palette(branding, info);
  text-decoration: none;

  &:focus,
  &:hover {
    color: darken(palette(branding, info), 10);
  }
}

.inline-link-arrow {
  align-items: center;
  display: inline-flex;
}

hr {
  border-bottom: 1px solid palette(muted, light);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1em 0;
}

.top-border__half {
  border-top: 1px solid #CFCFCF;
  content: '';
  display: block;
  margin-bottom: 62px;
  width: 173px;
}

address {
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
}

abbr,
acronym {
  border-bottom: 1px dotted #ddd;
  cursor: help;
}

.featured-section-title {
  color: $dk-gray;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
