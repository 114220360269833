.panel {
  border-radius: 5px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1), 0 2px 10px rgba(0, 0, 0, .2);
  font-family: $font1;
  margin: 0 0 2rem;
  padding: 1.5rem 2rem;

  .title {
    font-weight: 300;
  }

  li {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4em;
    padding-bottom: .4em;
  }

  a {
    color: palette(muted, light);
    display: block;
    transition: color .3s ease;
    width: 100%;

    &:hover,
    &:focus {
      color: palette(muted, med);
    }
  }

  &--location-partners {
    padding: 1rem 2rem;

    span {
      display: block;
      margin-bottom: 10px;
    }

    dl {
      color: palette(muted, med);
      font-size: 14px;
      line-height: 1.4em;
    }

    dt {
      display: inline;
      font-weight: 700;
    }

    dd {
      display: inline;
      margin: 0;
    }
  }

  &--benefits {
    /* ul {
      margin-left: 1.3rem;
    } */

    li {
      color: palette(muted, med);
      font-size: 14px;
      font-weight: 300;
      line-height: 19px;
      /* list-style-type: disc; */
      padding-bottom: .4em;

      &:not(:first-child) {
        padding-top: .4em;
      }
    }
  }

  &--external-links {
    li {
      line-height: 20px;
      padding-left: 23px;
    }

    a {
      // color: palette(muted, med);
      font-size: 14px;
    }

    svg {
      fill: palette(branding, info);
      height: 14px;
      margin-bottom: -1px;
      margin-left: -22px;
      margin-right: 4px;
      width: 15px;
    }
  }

  &--toc {
    li {
      &:not(:first-child) {
        padding-top: .4em;
      }

      &:not(:last-child) {
        border-bottom: 1px solid palette(muted, light);
      }
    }
  }

  &--downloads {
    li {

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      svg {
        fill: palette(muted, light);
        height: 14px;
        margin-right: 10px;
        width: 15px;
      }
    }

    .meta {
      color: #B4B4B4;
      font-size: 14px;
      line-height: 19px;
      margin-left: 25px;
      margin-top: 3px;

      span {
        display: inline-block;
        font-weight: 500;
        width: 38px;
      }
    }
  }
}