.body--search {

  .search__cards {
    @extend .grid__container;

    // resource cards
    .uk-card-body {
      padding: 0;
    }

    // Event cards
    .event-card {
      flex-wrap: wrap;
      margin-top: 0;
      padding: 0;

      &__image {
        max-width: 100%;
        width: 100%;
      }

      &__inner {
        margin-left: 0;
        margin-top: 25px;
        width: 100%;
      }
    }

    // Blog cards
    .blog-card {
      @extend .grid__card;
      box-shadow: none;
      margin-top: 0;
      padding: 0;

      img {
        @extend .rounded-img;
        margin-bottom: 25px;
        margin-right: 0;
      }
    }
  }
}
