.featured-solution {
  @extend .solution-block__one;

  .wrapper {
    @include mq(m) {
      align-items: center;
      display: flex;
    }
  }

  // Image
  &__image {
    @extend .solution-block__image;
    margin-bottom: 25px;

    @include mq(m) {
      margin-bottom: 0;
      width: 50%;
    }
  }

  // Content
  &__content {
    @extend .solution-block__content;
    @include mq(m) {
      margin-left: 40px;
      max-width: 425px;
      width: 50%;
    }
    @include mq(xl) {
      margin-left: 48px;
      max-width: 625px;
    }

    // Summary
    .summary {
      @include mq(m) {
        max-width: 370px;
      }
      @include mq(xl) {
        max-width: 625px;
      }
    }
  }
}
