.hero {
  $root: &;
  background-color: palette(branding, info);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -90px !important;
  padding-top: 90px;

  @include mq(m) {
    margin-top: -205px !important;
    padding-bottom: 180px;
    padding-top: 205px;
  }

  &__inner {
    align-items: stretch;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
    padding: 0 10%;
    text-align: center;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &.hero__link {
      font-family: $font1;
      margin-top: 20px;
    }
  }

  .lede {
    color: #fff;

    @include mq(m) {
      padding: 0 20%;
    }
  }

  &__search {
    width: 100%;

    form {
      align-items: center;
      display: flex;
      flex-direction: column;

      input {
        height: 3em;
      }

      @include mq(m) {
        flex-direction: row;
      }
    }
  }

  &--default {
    @include angled-edge('inside bottom', 'upper right', #fff);
  }

  &--minimal {
    margin-top: 0!important;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;

    .hero__background {
      min-height: 511px;
      border-radius: 16px;
      background-size: cover;
    }
  }

  &--resource-search {
    @include angled-edge('inside bottom', 'upper right', #f5f5f5);
    padding-bottom: 120px;

    @include mq(m) {
      padding-bottom: 180px;
    }

    #{$root}__inner {
      @include mq(m) {
        padding: 0 20%;
      }
    }
  }

  &--heading {

    @include mq(m) {
      background-position: center bottom -80px;
    }

    #{$root}__inner {
      padding-bottom: 3vh;
      padding-top: 2vh;
      text-align: left;

      @include mq(m) {
        padding-bottom: 0;
      }

      h1 {
        font-size: 58px;
        font-weight: 900;
        line-height: .9em;

        @include mq(m) {
          font-size: 78px;
        }
      }

      span {
        color: #FFF;
        display: block;
        font-size: 30px;
        font-weight: 300;
        line-height: 1.2em;
        margin-top: .3em;

        @include mq(m) {
          font-size: 32px;
          margin-left: 50px;
          width: 50%;
        }
      }

    }
  }

}
