.error404__no-results {
  background-color: $xlt-gray;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 1140px;
  padding: 64px 20px;
  text-align: center;

  @include mq(l) {
    border-radius: 16px;
  }

  h3 {
    color: $dk-gray;
    font-weight: 800;
    margin: 0 auto 18px auto;
    max-width: 352px;
  }

  p {
    color: $dk-gray;
    font-size: 14px;
    line-height: 23px;
    margin: 0 auto 25px auto;
    max-width: 352px;
  }

  a {
    @extend .button-outline;
    &:first-of-type {
      margin-right: 20px;
    }
  }
}