.lifecycle-navigator {
  $root: &;
  margin-top: -120px;
  position: relative;
  z-index: 2;

  &__inner {
    background: #fff;
    border: 2px solid palette(branding, primary);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(#000, .5);
    /* margin: 0; */
    padding: 3rem 2rem 2rem;
    position: relative;
    text-align: center;

    @include mq(m) {
      margin: 0 10%;
      padding: 3rem 15% 2rem;
    }
  }

  svg {
    margin: 0 auto 10px;
    width: 80%;

    @include mq(m) {
      max-width: 300px;
    }
  }

  p {
    color: palette(branding, primary);
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  &--inverted {
    background-color: palette(branding, primary);
    background-position: center center;
    background-size: cover;
    margin-top: 2rem;
    padding: 0 0 2rem;
    width: 100%;

    @include mq(m) {
      padding: 5rem 0;
    }

    svg {
      margin-bottom: 20px;
    }

    #{$root} {

      &__inner {
        background: transparent;
        border: 0;
        border-radius: none;
        box-shadow: none;
        color: #fff;
      }
    }

    p {
      color: #fff;
    }
  }

}