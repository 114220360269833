.button {
  @include smoothing(false);
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: inset .5px .5px 0 #000, inset -.5px -.5px 0 #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: $font1;
  font-size: 16px;
  font-weight: 500;
  padding: .5em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: all .1s ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  @each $label, $color in map-merge(
    map-get($palettes, branding),
    map-get($palettes, muted)
  ) {
    &--#{$label} {
      border: 1px solid $color;
      // Hack for 1.5px borders that we probably shouldn't do
      box-shadow: inset .5px .5px 0 $color, inset -.5px -.5px 0 $color, inset .5px -.5px 0 $color, inset -.5px .5px 0 $color;
      color: $color !important;

      &.fake-hover,
      &:hover,
      &:active {
        background: rgba($color, .05);
        border: 1px solid darken($color, 10);
        box-shadow: inset .5px .5px 0 darken($color, 10), inset -.5px -.5px 0 darken($color, 10), inset .5px -.5px 0 darken($color, 10), inset -.5px .5px 0 darken($color, 10);
        color: darken($color, 10);
        text-decoration: none;
      }
    }
  }

  &--sort {
    background-color: rgba(46, 46, 46, 0.05);
    text-transform: uppercase;
    border: 1px solid rgba(46, 46, 46, 0.2);
    padding: 10px 18px;
    box-shadow: none;
    font-weight: 800;
    font-size: 14px;
    border-radius: 4px;
    letter-spacing: 0.08em;
    &:hover {
      text-decoration: none;
    }

    .arrow {
      font-size: 18px;
      margin-left: 4px;
      vertical-align: 5%;
    }
  }

  &--icon {
    $color: palette(muted, light);
    $color-border: #E6E7E8;
    $color-hover: palette(muted, med);
    align-items: center;
    border: 1px solid $color-border;
    box-shadow: inset .5px .5px 0 $color-border, inset -.5px -.5px 0 $color-border, inset .5px -.5px 0 $color-border, inset -.5px .5px 0 $color-border;
    color: $color;
    display: inline-flex;
    padding: .2em .5em;

    svg {
      fill: $color;
      height: 13px;
      margin-right: 5px;
      transition: all .1s ease;
      width: 13px;
    }

    &.fake-hover,
    &:hover,
    &:focus {
      border: 1px solid $color-hover;
      box-shadow: inset .5px .5px 0 $color-hover, inset -.5px -.5px 0 $color-hover, inset .5px -.5px 0 $color-hover, inset -.5px .5px 0 $color-hover;
      color: $color-hover;
      text-decoration: none;

      svg {
        fill: $color-hover;
      }
    }
  }

  &--no-border {
    border: 0;
    box-shadow: none;

    &.fake-hover,
    &:hover,
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  &--large {
    padding: .5em 3em;
  }

  &--small {
    padding: .2em 8px;
  }

  &--inverted {
    color: #FFF;

    @each $label, $color in map-get($palettes, branding) {
      &.button--#{ $label } {
        background-color: $color !important;
        color: #fff!important;

        &:hover,
        &:focus {
          background: darken($color, 10)!important;
          border: 1px solid darken($color, 10)!important;
          box-shadow: inset .5px .5px 0 darken($color, 10), inset -.5px -.5px 0 darken($color, 10), inset .5px -.5px 0 darken($color, 10), inset -.5px .5px 0 darken($color, 10)!important;
          color: #fff !important;
        }
      }
    }
  }
}

// Button outline
.button-outline {
  border: 2px solid $dk-gray;
  border-radius: 100px;
  color: $dk-gray;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  height: 47px;
  line-height: 14px;
  letter-spacing: 0.08em;
  padding: 15px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: $dk-gray;
    color: palette(muted,white);

    svg {
      fill: palette(muted,white);
    }
  }
}

// Button outline
.button-outline-w {
  border: 2px solid $white-gray;
  border-radius: 100px;
  color: $dk-gray;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  height: 47px;
  line-height: 14px;
  letter-spacing: 0.08em;
  padding: 15px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: $dk-gray;
    color: palette(muted,white);

    svg {
      fill: palette(muted,white);
    }
  }
}

// Resource button
.resource-block__view-all a {
  @extend .button-outline;
  border-radius: 60px;
}

.resource-block__view-all-white a {
  @extend .button-outline-w;
  border-radius: 60px;
}
