// Name:            Accordion
// Description:     Component to create accordions
//
// Component:       `uk-accordion`
//
// Sub-objects:     `uk-accordion-title`
//                  `uk-accordion-content`
//
// States:          `uk-open`
//
// ========================================================================


// Variables
// ========================================================================

$accordion-item-margin-top:                     $global-margin !default;

$accordion-title-font-size:                     $global-medium-font-size !default;
$accordion-title-line-height:                   1.4 !default;

$accordion-content-margin-top:                  $global-margin !default;


/* ========================================================================
   Component: Accordion
 ========================================================================== */

.uk-accordion {
    padding: 0;
    list-style: none;
    @if(mixin-exists(hook-accordion)) {@include hook-accordion();}
}


/* Item
 ========================================================================== */

.uk-accordion > :nth-child(n+2) {
    margin-top: $accordion-item-margin-top;
    @if(mixin-exists(hook-accordion-item)) {@include hook-accordion-item();}
}


/* Title
 ========================================================================== */

.uk-accordion-title {
    margin: 0;
    font-size: $accordion-title-font-size;
    line-height: $accordion-title-line-height;
    cursor: pointer;
    @if(mixin-exists(hook-accordion-title)) {@include hook-accordion-title();}
}


/* Content
 ========================================================================== */

.uk-accordion-content {
    margin-top: $accordion-content-margin-top;
    @if(mixin-exists(hook-accordion-content)) {@include hook-accordion-content();}
}

/*
 * Micro clearfix
 */

.uk-accordion-content::before,
.uk-accordion-content::after {
    content: "";
    display: table;
}

.uk-accordion-content::after { clear: both; }

/*
 * Remove margin from the last-child
 */

 .uk-accordion-content > :last-child { margin-bottom: 0; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-accordion-misc)) {@include hook-accordion-misc();}

// @mixin hook-accordion(){}
// @mixin hook-accordion-item(){}
// @mixin hook-accordion-title(){}
// @mixin hook-accordion-content(){}
// @mixin hook-accordion-misc(){}


// Inverse
// ========================================================================



// @mixin hook-inverse-accordion-item(){}
// @mixin hook-inverse-accordion-title(){}