.solution-call-outs-card {
  margin: 20px auto 80px auto;

  > div:nth-child(1) {
    .uk-card-body {
      padding: 65px 65px 75px;
    }
  }

  .uk-card-body {
    padding: 50px;
  }

  .uk-card-primary {
    background: $blue;
  }

  .left-callout {
    margin-bottom: 20px;
    @media (min-width: 960px) {
      margin-bottom: 0;
    }
  }

  .right-callout {
    padding-left: 0;
    @media (min-width: 960px) {
      padding-left: 20px;
    }
  }
}
