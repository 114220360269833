@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=066787bd-3eac-4aa6-a54a-b154010580d4");
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 300;
    src:url("/static/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
    src:url("/static/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("/static/fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("/static/fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("/static/fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype"),url("/static/fonts/0979215b-3a1b-4356-9c76-e90fa4551f1d.svg#0979215b-3a1b-4356-9c76-e90fa4551f1d") format("svg");
}
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 300;
    font-style: italic;
    src:url("/static/fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix");
    src:url("/static/fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix") format("eot"),url("/static/fonts/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),url("/static/fonts/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff"),url("/static/fonts/04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf") format("truetype"),url("/static/fonts/d0a3e813-1257-4b66-812a-36101faa0fb3.svg#d0a3e813-1257-4b66-812a-36101faa0fb3") format("svg");
}
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 500;
    src:url("/static/fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
    src:url("/static/fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),url("/static/fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("/static/fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),url("/static/fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype"),url("/static/fonts/3604edbd-784e-4ca7-b978-18836469c62d.svg#3604edbd-784e-4ca7-b978-18836469c62d") format("svg");
}
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 500;
    font-style:italic;
    src:url("/static/fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix");
    src:url("/static/fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix") format("eot"),url("/static/fonts/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),url("/static/fonts/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff"),url("/static/fonts/cee053ec-4b41-4506-a1fe-796261690610.ttf") format("truetype"),url("/static/fonts/38a556e4-b6e5-48c6-aece-b1d470af124e.svg#38a556e4-b6e5-48c6-aece-b1d470af124e") format("svg");
}
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 900;
    src:url("/static/fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
    src:url("/static/fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),url("/static/fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("/static/fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),url("/static/fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype"),url("/static/fonts/93603a74-2be0-436c-83e1-68f9ef616eaf.svg#93603a74-2be0-436c-83e1-68f9ef616eaf") format("svg");
}
@font-face{
    font-family:"Avenir fontsdotnet";
    font-weight: 900;
    font-style:italic;
    src:url("/static/fonts/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix");
    src:url("/static/fonts/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix") format("eot"),url("/static/fonts/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"),url("/static/fonts/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff"),url("/static/fonts/5ba5a010-7470-4d9d-8a49-2920dc1be1f8.ttf") format("truetype"),url("/static/fonts/6d4a6a8c-ef50-422f-8456-88d479e0b23d.svg#6d4a6a8c-ef50-422f-8456-88d479e0b23d") format("svg");
}
@font-face{
    font-family:"Helvetica fontsdotnet";
    font-weight: 300;
    src:url("/static/fonts/05598d95-f297-4c7f-a825-ab617e7e045f.eot?#iefix");
    src:url("/static/fonts/05598d95-f297-4c7f-a825-ab617e7e045f.eot?#iefix") format("eot"),url("/static/fonts/89294146-207c-4803-91fe-338f0d0094bd.woff2") format("woff2"),url("/static/fonts/57055fe4-c2d3-4287-bac2-eece43fdcbd3.woff") format("woff"),url("/static/fonts/2922d55c-8df4-4066-b7b0-eec2d1524330.ttf") format("truetype"),url("/static/fonts/f8343ddf-e94d-471e-95b0-3b435acc2499.svg#f8343ddf-e94d-471e-95b0-3b435acc2499") format("svg");
}
@font-face{
    font-family:"Helvetica fontsdotnet";
    font-weight: 300;
    font-style:italic;
    src:url("/static/fonts/d3ccb7ea-0438-4c8f-8d67-09fc47f702f8.eot?#iefix");
    src:url("/static/fonts/d3ccb7ea-0438-4c8f-8d67-09fc47f702f8.eot?#iefix") format("eot"),url("/static/fonts/ac4134ce-b28e-4df9-b88c-a67db965774e.woff2") format("woff2"),url("/static/fonts/6389110a-e983-4d95-8d1f-5a87de018aac.woff") format("woff"),url("/static/fonts/940b0050-c3b7-41ea-af4b-857bc9e8313d.ttf") format("truetype"),url("/static/fonts/10fdc723-bfd0-4462-b99c-a97a1b174b16.svg#10fdc723-bfd0-4462-b99c-a97a1b174b16") format("svg");
}
@font-face{
    font-family:"Helvetica fontsdotnet";
    font-weight: 500;
    src:url("/static/fonts/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix");
    src:url("/static/fonts/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix") format("eot"),url("/static/fonts/7ce0a2f5-eb00-46aa-919c-5b3f3667646c.woff2") format("woff2"),url("/static/fonts/cad22c74-45b4-4c49-9e6a-0cd3768a7bc7.woff") format("woff"),url("/static/fonts/8ebb9b04-d12d-488e-aa96-0bb6e6777336.ttf") format("truetype"),url("/static/fonts/7672ef16-fedc-4b60-8337-04b537a6ada5.svg#7672ef16-fedc-4b60-8337-04b537a6ada5") format("svg");
}
@font-face{
    font-family:"Helvetica fontsdotnet";
    font-weight: 500;
    font-style:italic;
    src:url("/static/fonts/ec51a024-d2de-44f6-8c2c-4fa4b59564cb.eot?#iefix");
    src:url("/static/fonts/ec51a024-d2de-44f6-8c2c-4fa4b59564cb.eot?#iefix") format("eot"),url("/static/fonts/3e13f208-91f2-41ec-b1bf-e5b29d486821.woff2") format("woff2"),url("/static/fonts/17d3cf02-3e99-48c3-953a-b26082b28949.woff") format("woff"),url("/static/fonts/69bad428-ee4f-4bad-9fee-41ce37f8d3f8.ttf") format("truetype"),url("/static/fonts/1448061d-3b67-423d-8b23-e12b9b7d9437.svg#1448061d-3b67-423d-8b23-e12b9b7d9437") format("svg");
}
