.body {

  // Homepage
  &--homepage {
    .featured-solution {
      .featured-solution__content {
        margin-top: 0;
      }
    }
  }

  // Solutions entry page
  &--solutions-entry {

    .simple-text {
      font-family: $font1;
      text-align: center;

      @include mq(m) {
        padding: 0 10%;
      }

      p {
        font-size: 21px;
        line-height: 32px;
      }
    }

    .box-links {
      .title {
        &:before {
          display: none;
        }
      }
    }
  }

  // Solutions index page
  &--solutions-index {

    .main {
      > .section:first-of-type {
        @extend .page-header;
      }
    }

    .solutions__lede {
      font-family: $font1;
      text-align: center;
      margin-top: 4rem;
      margin-bottom: 4rem;

      @include mq(m) {
        padding: 0 10%;
      }

      p {
        font-size: 21px;
        line-height: 32px;
      }
    }

    // Featured solutions
    .featured-solutions {
      @extend .main-wrapper;
      @extend .columns;
      margin: 3.4rem auto 0 auto;

      // Resource button
      .resource-block__view-all a {
        padding: 15px 30px;
      }

      // Left content
      .content__left {
        margin-bottom: 55px;

        > h3 {
          color: $dk-gray;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;

          &.title {
            font-weight: 800;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 12px;
          }
        }

        .featured-resource__content {
          h3.title {
            @extend h2;
          }
        }
      }
    }

    // Solution cards
    .solution-block__cards {
      @extend .solution-block__card-horizontal;
    }

  }

  // Solutions page
  &--solutions {
    .main {
      > .section:first-of-type {
        @extend .page-header;
      }

      > .wrapper {
        @extend .main-wrapper;

        > .wrapper {
          padding: 0;

          .solution-block__cards {
            @include mq(l) {
              grid-column-gap: 2%;
              flex-wrap: nowrap;
            }

            .solution-block__card {
              margin-bottom: 55px;

              @include mq(l) {
                max-width: 478px;
                padding: 0;
              }
            }
          }

          // removes `view all` button from under the `solution-block__cards` section at the top of the page
          // @todo: Create separate templates so we don't have to use display none
          .resource-block__view-all {
            display: none;
          }
        }
      }
    }

    .row-one {
      .featured-section-title {
        margin-bottom: 20px;
      }
    }

    .featured-title {
      @extend .featured-section-title;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 60px;
    }

    .resource-block__view-all {
      margin-bottom: 45px;
    }

    .solution-block__cards {
      max-width: 965px;

      .solution-block__card {
        @include mq(l) {
          padding: 0 7px;
          width: 50%;

          .solution-block__content {
            max-width: 462px;
          }
        }
      }
    }

    .no-event {
      .solution-block__cards {
        .solution-block__card:first-child {
          border-top: 0 solid transparent;
          padding-top: 0;
        }
      }
    }

    .columns {
      @extend .main-wrapper;
      padding: 0;

      .wrapper {
        padding: 0;
      }

      .content__left,
      .content__right {

        .featured-title {
          margin: 20px auto;
          text-align: left;
        }

        &:before {
          @extend .top-border__half;
        }
      }

      .content__left {
        .featured-event {
          border-bottom: 1px solid #CFCFCF;
          margin-bottom: 38px;

          .event-card {
            margin-bottom: 50px;
          }

          .event-card__title {
            margin-bottom: 4px;

            a {
              @extend h2;
            }
          }
        }
        .solution-block__cards {
          @extend .solution-block__card-horizontal;

          .solution-block__card {
            &:first-of-type {
              border-top:0;
              padding-top: 0;
            }
          }
        }
      }
    }
  }

}

// Sub Solution Page
.featured-resource__content {
  .tags {
    margin-bottom: 5px;

    li {
      @extend .tag;
      display: inline-block;
      font-style: italic;
      line-height: 21px;
      padding: 0;

      &:before {
        content:'';
      }

      &.hovertags:hover ul { display: inline; }

      &.hovertags {
        ul {
          display: none;
          background-color: palette(muted, xlight);
          border-radius: 5px;
          bottom: 25px;
          padding: 10px;
          position: absolute;
          width: 100px;

          @include mq(l) {
            width: 160px;
          }

          li {
            display: block;
            padding: 0;
          }
        }
      }
    }
  }
}

.solutions__lede {
  font-family: $font1;
  padding: 5rem 5rem;
  text-align: center;
}
