.page-header {
    max-width: 678px;
    margin: 0 auto;
    padding: 15px 20px 55px;
    text-align: center;

    h2 {
        font-size: 32px;
        font-weight: 800;
        line-height: 130%;
    }

    p {
        color: $dk-gray;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }
}

.body {
    > .section:first-of-type {
        @extend .page-header;
    }

    // Homepage page header
    &.body--homepage {
        > .section:first-of-type {
            h2 {
                @extend h1;
            }
        }
    }
}
