.image-copy-list {
    .image-copy-list__listing {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: row;
    }
    &--list {
        .image-copy-list__listing {
            .image-copy-list__item {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                @include mq(m) {
                    flex-direction: row;
                    align-items: center;
                }
                .image-copy-list__image, .image-copy-list__body {
                    max-width: 100%;
                    margin: 0 0 1em;
                    flex-direction: column;
                    @include mq(m) {
                        width: 46%;
                    }
                }
            }
        }
    }
    &--grid {
        .image-copy-list__listing {
            .image-copy-list__item {

                width: 100%;
                display: block;
                @include mq(m) {
                    width: 48%;
                }
            }
        }
    }
}